<ng-container [ngSwitch]="widget.type">
  <crm-widget-tasks *ngSwitchCase="WidgetType.TASKS" [widgetData]="widget"></crm-widget-tasks>
  <crm-widget-contracts *ngSwitchCase="WidgetType.CONTRACTS" [widgetData]="widget"></crm-widget-contracts>
  <crm-widget-traffic *ngSwitchCase="WidgetType.TRAFFIC" [widgetData]="widget"></crm-widget-traffic>
  <crm-widget-workload *ngSwitchCase="WidgetType.WORKLOAD" [widgetData]="widget"></crm-widget-workload>
  <crm-widget-payments *ngSwitchCase="WidgetType.PAYMENTS" [widgetData]="widget"></crm-widget-payments>
  <crm-widget-reports *ngSwitchCase="WidgetType.REPORTS" [widgetData]="widget"></crm-widget-reports>
  <crm-widget-contragent-revenues
    *ngSwitchCase="WidgetType.TENANT_REVENUE"
    [widgetData]="widget"
  ></crm-widget-contragent-revenues>
</ng-container>

import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { UserLoginResponseDto } from '@zaoblako/shared/modules/security/dtos/user/user.login.response.dto';
import { AuthService } from '@zaoblako/web/core/services/security/auth.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'crm-layout',
  templateUrl: './common.layout.component.html',
  styleUrls: ['./common.layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonLayoutComponent implements OnInit {
  public items: MenuItem[];
  public menubar: MenuItem[];

  public get currentUser(): UserLoginResponseDto {
    return this.authService.currentUser;
  }

  public menuMinimized: boolean = localStorage.getItem('user.settings.menu-minimized') === 'true';
  private windowWidth: number;

  public constructor(private readonly authService: AuthService) {}

  public ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    if (!localStorage.getItem('user.settings.menu-minimized') && this.windowWidth < 992) {
      this.menuMinimized = true;
    }

    this.menubar = [
      {
        label: 'Настройки',
        icon: 'pi pi-cog',
        routerLink: '/user/settings',
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: 'Выход',
        icon: 'pi pi-sign-out',
        command: () => {
          this.logout();
        }
      }
    ];

    this.items = [
      {
        label: 'Рабочий стол',
        icon: 'pi pi-desktop',
        routerLink: '/',
        routerLinkActiveOptions: { exact: true },
        command: (event) => {
          this.clickKeyMenu(event);
        }
      },
      {
        label: 'Борд',
        icon: 'pi pi-external-link',
        url: 'https://board.engeocom.com',
        target: '_blank',
        command: (event) => {
          this.clickKeyMenu(event);
        }
      },
      {
        label: 'Заявки',
        icon: 'pi pi-book',
        routerLink: '/task',
        routerLinkActiveOptions: { exact: false },
        command: (event) => {
          this.clickKeyMenu(event);
        }
      },
      {
        label: 'Бюджетирование',
        icon: 'pi pi-dollar',
        routerLink: '/budget',
        routerLinkActiveOptions: { exact: false },
        command: (event) => {
          this.clickKeyMenu(event);
        }
      },
      {
        label: 'Клиенты',
        icon: 'pi pi-id-card',
        routerLink: '/client',
        routerLinkActiveOptions: { exact: false },
        command: (event) => {
          this.clickKeyMenu(event);
        }
      },
      {
        label: 'Договоры',
        icon: 'pi pi-file',
        routerLink: '/contract',
        routerLinkActiveOptions: { exact: false },
        command: (event) => {
          this.clickKeyMenu(event);
        }
      },
      {
        label: 'Помещения',
        icon: 'pi pi-home',
        routerLink: '/location',
        routerLinkActiveOptions: { exact: false },
        command: (event) => {
          this.clickKeyMenu(event);
        }
      },
      {
        label: 'Пользователи',
        icon: 'pi pi-users',
        routerLink: '/user',
        routerLinkActiveOptions: { exact: false },
        command: (event) => {
          this.clickKeyMenu(event);
        }
      },
      {
        label: 'Задолженность',
        icon: 'pi pi-exclamation-triangle',
        routerLink: '/report/debt',
        routerLinkActiveOptions: { exact: false },
        command: (event) => {
          this.clickKeyMenu(event);
        }
      },
      {
        label: 'Дебаркадер',
        icon: 'pi pi-sitemap',
        routerLink: '/debarcader',
        routerLinkActiveOptions: { exact: false },
        command: (event) => {
          this.clickKeyMenu(event);
        }
      },
      {
        label: 'Помощь',
        icon: 'pi pi-question-circle',
        routerLink: '/help',
        routerLinkActiveOptions: { exact: true },
        command: (event) => {
          this.clickKeyMenu(event);
        }
      },
      {
        label: 'Техническая поддержка',
        icon: 'pi pi-phone',
        routerLink: '/reglament',
        routerLinkActiveOptions: { exact: true },
        command: (event) => {
          this.clickKeyMenu(event);
        }
      }
    ];
  }

  @HostListener('window:resize')
  private onWindowResize() {
    if (window.innerWidth < 992 && this.windowWidth >= 992 && !this.menuMinimized) {
      this.menuMinimized = true;
      this.saveMenuState();
    } else if (window.innerWidth >= 992 && this.windowWidth < 992 && this.menuMinimized) {
      this.menuMinimized = false;
      this.saveMenuState();
    }
    this.windowWidth = window.innerWidth;
  }

  public logout() {
    this.authService.logout(location.pathname + location.search);
  }

  public saveMenuState() {
    localStorage.setItem('user.settings.menu-minimized', JSON.stringify(this.menuMinimized));
  }

  public clickKeyMenu(event: any) {
    if (event.originalEvent.ctrlKey || event.originalEvent.metaKey) {
      window.open(event.item.routerLink, '_blank');
    }
  }
}

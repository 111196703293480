import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WidgetTasksGroupedFindDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/tasks/widget.tasks.grouped.find.dto';
import { WidgetTasksQueryDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/tasks/widget.tasks.query.dto';
import { WidgetFindDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/widget.find.dto';
import { TaskGroupedWidgetEnum } from '@zaoblako/shared/modules/dashboard/enums/task/task.grouped.widget.enum';
import { PeriodEnum } from '@zaoblako/shared/modules/report/enums/period.enum';
import { TaskFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.find.dto';
import { CrmTableColumn } from '@zaoblako/web/core/models/crm-table-column.interface';
import { DashboardService } from '@zaoblako/web/core/services/dashboard/dashboard.service';
import * as moment from 'moment-timezone';
import { unitOfTime } from 'moment-timezone';

type ColumnName = 'task' | 'date' | 'client' | 'template' | 'status' | 'tag';

@Component({
  selector: 'crm-widget-tasks',
  templateUrl: './widget-tasks.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetTasksComponent implements OnInit {
  @Input()
  public widgetData: WidgetFindDto;

  public queryParams: WidgetTasksQueryDto = {
    limit: 50,
    offset: 0,
    from: moment().startOf('month').format('YYYY-MM-DD') as unknown as Date,
    to: moment().endOf('month').format('YYYY-MM-DD') as unknown as Date
  };

  public loading = false;
  public tasks: TaskFindDto[] = [];
  public grouped: WidgetTasksGroupedFindDto[];
  public count: number;
  public paginationLoading = false;

  public readonly groupOptions: { label: string; value: TaskGroupedWidgetEnum; parse: string }[] = [
    { label: 'Шаблон', value: TaskGroupedWidgetEnum.BY_TEMPLATE, parse: 'byTemplate' },
    { label: 'Статус', value: TaskGroupedWidgetEnum.BY_STATUS, parse: 'byStatus' }
  ];

  public selectedGroup: { label: string; value: TaskGroupedWidgetEnum; parse: string };

  public readonly columns: CrmTableColumn<ColumnName>[] = [
    { label: 'Номер', name: 'task', required: true },
    { label: 'Дата', name: 'date' },
    { label: 'Клиент', name: 'client' },
    { label: 'Шаблон', name: 'template' },
    { label: 'Статус', name: 'status' },
    { label: 'Тэг', name: 'tag' }
  ];
  public selectedColumns: CrmTableColumn<ColumnName>[] = [];

  public constructor(
    private readonly dashboardService: DashboardService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.selectedGroup = this.groupOptions.find((group) => this.widgetData.groupBy === group.value);
    this.loadTasks();
  }

  public loadTasks(isConcat = false) {
    if (isConcat) {
      this.paginationLoading = true;
      this.queryParams.offset += this.queryParams.limit;
    } else {
      this.loading = true;
      this.queryParams.offset = 0;
    }
    this.cdRef.markForCheck();

    if (this.widgetData.filter.period === PeriodEnum.DAY) {
      this.queryParams.from = moment().startOf('day').format('YYYY-MM-DD') as unknown as Date;
      this.queryParams.to = moment().endOf('day').format('YYYY-MM-DD') as unknown as Date;
    } else if (this.widgetData.filter.period === PeriodEnum.WEEK) {
      this.queryParams.from = moment().startOf('week').format('YYYY-MM-DD') as unknown as Date;
      this.queryParams.to = moment().endOf('week').format('YYYY-MM-DD') as unknown as Date;
    } else if (this.widgetData.filter.period === PeriodEnum.MONTH) {
      this.queryParams.from = moment().startOf('month').format('YYYY-MM-DD') as unknown as Date;
      this.queryParams.to = moment().endOf('month').format('YYYY-MM-DD') as unknown as Date;
    }

    this.queryParams.statusIds = this.widgetData.filter.statuses?.map((status) => status.id);
    this.queryParams.templateIds = this.widgetData.filter.templates?.map((template) => template.id);
    this.queryParams.responsibleIds = this.widgetData.filter.responsible?.map((responsible) => responsible.id);
    this.queryParams.agreementsIds = this.widgetData.filter.agreement?.map((agreement) => agreement.id);
    this.queryParams.tagIds = this.widgetData.filter.tag?.map((tag) => tag.id);
    this.queryParams.neededAgreement = this.widgetData.filter.isMyApproval === true;
    this.queryParams.ignorePeriod = this.widgetData.filter.ignorePeriod === true;
    this.queryParams.groupBy = this.widgetData.groupBy;
    this.queryParams.sortColumn = this.widgetData.sortColumn;
    this.queryParams.sortDirection = this.widgetData.sortDirection;

    this.selectedColumns = this.columns.filter((column) => this.widgetData.selectedColumns?.includes(column.name));
    this.selectedGroup = this.groupOptions.find((group) => this.widgetData.groupBy === group.value);

    this.dashboardService.tasksWidget(this.queryParams).subscribe({
      next: (data) => {
        this.tasks = isConcat ? this.tasks.concat(data.tasks) : data.tasks;
        this.grouped = data.grouped;
        this.count = data.count;
        this.loading = false;
        this.paginationLoading = false;
        this.cdRef.markForCheck();
      }
    });
  }

  public onScrollEnd(): void {
    if (this.tasks.length < this.count && !this.loading && !this.paginationLoading) {
      this.loadTasks(true);
    }
  }

  public toTask(task: any): TaskFindDto {
    return task as TaskFindDto;
  }

  public isSelectColumn(name: ColumnName): boolean {
    return this.selectedColumns?.some((selectColumn) => selectColumn.name === name);
  }

  public navigateToList(group?: { id: number }) {
    let period: unitOfTime.StartOf;
    switch (this.widgetData.filter.period) {
      case PeriodEnum.DAY: {
        period = 'day';
        break;
      }
      case PeriodEnum.WEEK: {
        period = 'week';
        break;
      }
      case PeriodEnum.MONTH: {
        period = 'month';
        break;
      }
    }

    if (this.widgetData.filter?.ignorePeriod) {
      period = 'year';
    }

    let queryParams: any = {
      from: moment().startOf(period).format('YYYY-MM-DD'),
      to: moment().endOf(period).format('YYYY-MM-DD'),
      neededAgreement: this.widgetData.filter.isMyApproval
    };
    if (this.widgetData.filter?.templates?.length) {
      queryParams.templateIds = this.widgetData.filter?.templates.map((template) => template.id);
    }
    if (this.widgetData.filter?.responsible?.length) {
      queryParams.responsibleIds = this.widgetData.filter?.responsible.map((responsible) => responsible.id);
    }
    if (this.widgetData.filter?.statuses?.length) {
      queryParams.statusIds = this.widgetData.filter?.statuses.map((status) => status.id);
    }
    if (this.widgetData.filter?.tag?.length) {
      queryParams.tagIds = this.widgetData.filter?.tag.map((tag) => tag.id);
    }

    if (group && this.selectedGroup.value === TaskGroupedWidgetEnum.BY_TEMPLATE) {
      queryParams.templateIds = [group.id];
    }
    if (group && this.selectedGroup.value === TaskGroupedWidgetEnum.BY_STATUS) {
      queryParams.statusIds = [group.id];
    }
    const url = String(this.router.createUrlTree(['/task'], { queryParams: queryParams }));
    window.open(url, '_blank');
  }
}


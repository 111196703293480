<p-dialog
  [visible]="isShowDialogCreateDashboard$ | async"
  (visibleChange)="closeDialog()"
  [style]="{ width: '300px' }"
  header="Создание вкладки"
>
  <crm-dashboard-form *ngIf="!loading" (save)="save($event)"></crm-dashboard-form>

  <crm-spinner *ngIf="loading"></crm-spinner>
</p-dialog>

<crm-tree-select
  [options]="_types"
  [(selectedItem)]="_selectedWidgetType"
  [groupsDisabled]="groupsDisabled"
  [class]="styleClass"
  [label]="label"
  [placeholder]="placeholder"
  [scrollHeight]="scrollHeight"
  [appendTo]="appendTo"
  (panelShow)="panelShow.emit()"
  (panelHide)="panelHide.emit()"
  (selectedItemChange)="changeSelectedWidgetType($event)"
  display="comma"
  dataKey="type"
  selectionMode="single"
></crm-tree-select>

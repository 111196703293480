<crm-dashboard-widget [widgetData]="widgetData" [loading]="loading" (reload)="load()">
  <div class="flex flex-wrap justify-content-between align-items-start">
    <div class="text-2xl flex align-items-center justify-content-between gap-2">
      <i class="pi pi-chart-line color-teal text-2xl"></i>
      <ng-container *ngIf="!loading; else statSkeleton">
        <p class="fw-semibold">{{ result?.value | number: '.0-2' }}</p>
      </ng-container>
    </div>

    <p-tag value="с НДС" styleClass="white-space-nowrap"></p-tag>
  </div>

  <p class="mt-2 -ml-3">
    <button
      pButton
      label="Подробнее..."
      class="p-button-text p-button-sm p-button-secondary"
      routerLink="/report/revenue"
      [queryParams]="{ from: queryParams.from, to: queryParams.to }"
    ></button>
  </p>

  <ng-template #statSkeleton>
    <p-skeleton width="120px" height="25px"></p-skeleton>
  </ng-template>
</crm-dashboard-widget>

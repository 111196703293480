import { Pipe, PipeTransform } from '@angular/core';
import { DashboardWidgetGroupTranslateEnum } from '@zaoblako/shared/modules/dashboard/enums/dashboard.widget.group.enum';

@Pipe({
  name: 'dashboardWidgetTypeGroupTranslate'
})
export class DashboardWidgetTypeGroupTranslatePipe implements PipeTransform {
  public transform(groupType: string): string {
    return DashboardWidgetGroupTranslateEnum[groupType] || '';
  }
}

import { DashboardWidgetTypeEnum } from '@zaoblako/shared/modules/dashboard/enums/dashboard.widget.type.enum';

export enum DashboardWidgetGroupEnum {
  /** Финансы */
  FINANCE = 'FINANCE',
  /** Заявки */
  TASKS = 'TASKS',
  /** Договоры */
  CONTRACTS = 'CONTRACTS',
  /** Пользователи */
  USERS = 'USERS',
  /** Статистика */
  STATISTICS = 'STATISTICS',
  /** Другое */
  OTHER = 'OTHER',
}

export const DashboardWidgetGroupTranslateEnum: Record<DashboardWidgetGroupEnum, string> = {
  [DashboardWidgetGroupEnum.FINANCE]: 'Финансы',
  [DashboardWidgetGroupEnum.TASKS]: 'Заявки',
  [DashboardWidgetGroupEnum.CONTRACTS]: 'Договоры',
  [DashboardWidgetGroupEnum.USERS]: 'Пользователи',
  [DashboardWidgetGroupEnum.STATISTICS]: 'Статистика',
  [DashboardWidgetGroupEnum.OTHER]: 'Другое',
}

export const DashboardWidgetGroupRelationEnum: Record<DashboardWidgetGroupEnum, DashboardWidgetTypeEnum[]> = {
  [DashboardWidgetGroupEnum.FINANCE]: [],
  [DashboardWidgetGroupEnum.TASKS]: [DashboardWidgetTypeEnum.TASKS],
  [DashboardWidgetGroupEnum.CONTRACTS]: [DashboardWidgetTypeEnum.CONTRACTS],
  [DashboardWidgetGroupEnum.USERS]: [],
  [DashboardWidgetGroupEnum.STATISTICS]: [
    DashboardWidgetTypeEnum.TRAFFIC,
    DashboardWidgetTypeEnum.WORKLOAD,
    DashboardWidgetTypeEnum.PAYMENTS,
    DashboardWidgetTypeEnum.TENANT_REVENUE,
    DashboardWidgetTypeEnum.REPORTS,
  ],
  [DashboardWidgetGroupEnum.OTHER]: [],
}
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DateRangeQueryDto } from '@zaoblako/shared/common/dtos/date.range.query.dto';
import { WidgetFindDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/widget.find.dto';
import { PaymentsWidgetFindDto } from '@zaoblako/shared/modules/document/dtos/payments.widget.find.dto';
import { PeriodEnum } from '@zaoblako/shared/modules/report/enums/period.enum';
import { DashboardService } from '@zaoblako/web/core/services/dashboard/dashboard.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'crm-widget-payments',
  templateUrl: './widget-payments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetPaymentsComponent implements OnInit {
  @Input() public widgetData: WidgetFindDto;

  public queryParams: DateRangeQueryDto = {
    from: moment().startOf('month').format('YYYY-MM-DD') as unknown as Date,
    to: moment().endOf('month').format('YYYY-MM-DD') as unknown as Date
  };

  public loading = false;
  public result: PaymentsWidgetFindDto;

  public constructor(private readonly dashboardService: DashboardService, private readonly cdRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.load();
  }

  public load() {
    this.loading = true;
    this.cdRef.markForCheck();

    if (this.widgetData.filter.period === PeriodEnum.DAY) {
      this.queryParams.from = moment().startOf('day').format('YYYY-MM-DD') as unknown as Date;
      this.queryParams.to = moment().endOf('day').format('YYYY-MM-DD') as unknown as Date;
    } else if (this.widgetData.filter.period === PeriodEnum.WEEK) {
      this.queryParams.from = moment().startOf('week').format('YYYY-MM-DD') as unknown as Date;
      this.queryParams.to = moment().endOf('week').format('YYYY-MM-DD') as unknown as Date;
    } else if (this.widgetData.filter.period === PeriodEnum.MONTH) {
      this.queryParams.from = moment().startOf('month').format('YYYY-MM-DD') as unknown as Date;
      this.queryParams.to = moment().endOf('month').format('YYYY-MM-DD') as unknown as Date;
    }

    this.dashboardService.paymentsWidget(this.queryParams).subscribe({
      next: (result) => {
        this.result = result;
        this.loading = false;
        this.cdRef.markForCheck();
      }
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { DashboardComponent } from '@zaoblako/web/modules/dashboard/components/dashboard/dashboard.component';
import { DashboardStateService } from '@zaoblako/web/modules/dashboard/components/dashboard/services/dashboard-state.service';
import { DashboardReportsComponent } from '@zaoblako/web/modules/dashboard/dumbs/reports/dashboard-reports.component';
import { DashboardWidgetNamePipe } from '@zaoblako/web/shared/pipes/dashboard-widget-name.pipe';
import { SharedModule } from '@zaoblako/web/shared/shared.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { MenuModule } from 'primeng/menu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabViewModule } from 'primeng/tabview';
import { DashboardAddWidgetComponent } from './components/dashboard/components/dashboard-add-widget/dashboard-add-widget.component';
import { DashboardAddComponent } from './components/dashboard/components/dashboard-add/dashboard-add.component';
import { DashboardEditDialogComponent } from './components/dashboard/components/dashboard-edit-dialog/dashboard-edit-dialog.component';
import { DashboardWidgetSwitcherComponent } from './components/dashboard/components/dashboard-widget-switcher/dashboard-widget-switcher.component';
import { DashboardWidgetComponent } from './components/dashboard/components/dashboard-widget/dashboard-widget.component';
import { WidgetTasksComponent } from './components/dashboard/components/widgets/widget-tasks/widget-tasks.component';
import { OldDashboardComponent } from './components/old-dashboard/old-dashboard.component';
import { DashboardClientIndexesComponent } from './dumbs/client-indexes/dashboard-client-indexes.component';
import { DashboardFormComponent } from './dumbs/dashboard-form/dashboard-form.component';
import { DashboardExpiringContractsComponent } from './dumbs/expiring-contracts/dashboard-expiring-contracts.component';
import { DashboardRecentCommentsComponent } from './dumbs/recent-comments/dashboard-recent-comments.component';
import { DashboardStatWidgetCardComponent } from './dumbs/stat-widget-card/dashboard-stat-widget-card.component';
import { DashboardStatsWidgetsComponent } from './dumbs/stats-widgets/dashboard-stats-widgets.component';
import { DashboardTaskStatsComponent } from './dumbs/task-stats/dashboard-task-stats.component';
import { DashboardTaskStepsComponent } from './dumbs/task-steps/dashboard-task-steps.component';
import { WidgetTypesSingleSelectComponent } from './dumbs/widget-types-single-select/widget-types-single-select.component';
import { WidgetContractsComponent } from './components/dashboard/components/widgets/widget-contracts/widget-contracts.component';
import { WidgetTrafficComponent } from './components/dashboard/components/widgets/widget-traffic/widget-traffic.component';
import { WidgetWorkloadComponent } from './components/dashboard/components/widgets/widget-workload/widget-workload.component';
import { WidgetPaymentsComponent } from './components/dashboard/components/widgets/widget-payments/widget-payments.component';
import { WidgetContragentRevenuesComponent } from './components/dashboard/components/widgets/widget-contragent-revenues/widget-contragent-revenues.component';
import { WidgetReportsComponent } from './components/dashboard/components/widgets/widget-reports/widget-reports.component';

const dashboardRoutes: Route[] = [
  {
    path: '',
    component: DashboardComponent,
    data: {
      title: 'Рабочий стол - АТРИУМ CRM'
    }
  },
  {
    path: 'old-dashboard',
    component: OldDashboardComponent,
    data: {
      title: 'Рабочий стол - АТРИУМ CRM'
    }
  }
];

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardStatWidgetCardComponent,
    DashboardStatsWidgetsComponent,
    DashboardTaskStatsComponent,
    DashboardReportsComponent,
    DashboardRecentCommentsComponent,
    DashboardExpiringContractsComponent,
    DashboardTaskStepsComponent,
    DashboardClientIndexesComponent,
    OldDashboardComponent,
    DashboardAddComponent,
    DashboardFormComponent,
    DashboardEditDialogComponent,
    DashboardAddWidgetComponent,
    WidgetTypesSingleSelectComponent,
    DashboardWidgetSwitcherComponent,
    DashboardWidgetComponent,
    WidgetTasksComponent,
    WidgetContractsComponent,
    WidgetTrafficComponent,
    WidgetWorkloadComponent,
    WidgetPaymentsComponent,
    WidgetContragentRevenuesComponent,
    WidgetReportsComponent
  ],
  imports: [
    RouterModule.forChild(dashboardRoutes),
    SharedModule,
    MenuModule,
    TabViewModule,
    CommonModule,
    SelectButtonModule,
    SortablejsModule
  ],
  providers: [DashboardStateService, DashboardWidgetNamePipe],
  exports: [DashboardStatWidgetCardComponent],
  bootstrap: []
})

export class DashboardModule {}

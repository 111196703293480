export enum TaskAgreementStatusEnum {
  /**
   * Согласовано
   */
  TASK_AGREEMENT_AGREED = 'AGREED',
  /**
   * Отказано
   */
  TASK_AGREEMENT_DISAGREED = 'DISAGREED',
  /**
   * Ожидает согласования
   */
  TASK_AGREEMENT_WAITING = 'WAITING',
}

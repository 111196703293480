<div class="flex align-items-end gap-3 mb-4">
  <h2 class="m-0">Рабочий стол</h2>

  <a routerLink="/old-dashboard">
    <i class="pi pi-external-link"></i>
    Старый борд
  </a>
</div>

<div class="flex flex-wrap gap-3">
  <div class="flex-1">
    <p-tabMenu
      [model]="dashboardTabItems$ | async | orderBy: 'state.order:number'"
      [activeItem]="activeDashboardTabItem$ | async"
    >
      <ng-template pTemplate="item" let-item>
        <ng-container *ngIf="item.label !== '+'">
          {{ item.label }}
          <i class="pi pi-times ml-2 cursor-pointer" (click)="openDialogDelete(item)"> </i>
        </ng-container>
        <ng-container *ngIf="item.label === '+'">
          <i class="pi pi-plus" style="font-size: 1.27rem"></i>
        </ng-container>
      </ng-template>
    </p-tabMenu>
  </div>
  <div>
    <div class="flex h-full gap-2 justify-content-end align-content-start align-items-center">
      <button pButton label="Виджет" icon="pi pi-plus" class="p-button-success" (click)="openDialogCreate()"></button>

      <div
        class="p-2 flex align-items-center text-lg text-yellow-800 bg-yellow-200 border-round font-medium uppercase"
        style="height: 36px"
      >
        <i class="pi pi-fw pi-calendar text-base mr-1"></i>
        {{ currentDate | date: 'LLLL YYYY' }}
      </div>

      <crm-dashboard-edit-dialog></crm-dashboard-edit-dialog>
    </div>
  </div>
</div>

<div
  *ngIf="(loading$ | async) === false"
  class="grid mt-3"
  [sortablejs]="(selectDashboard$ | async)?.widgets | orderBy: 'position:number'"
  [sortablejsOptions]="optionsSortable"
>
  <div
    *ngFor="let widget of (selectDashboard$ | async)?.widgets | orderBy: 'position:number'"
    class="col-12"
    [ngClass]="{
      'lg:col-6 xl:col-4': widget.width === 1,
      'xl:col-8': widget.width === 2,
      'dashboard-height-1': widget.height === 1,
      'dashboard-height-2': widget.height === 2,
      'dashboard-height-3': widget.height === 3
    }"
  >
    <crm-dashboard-widget-switcher [widget]="widget"></crm-dashboard-widget-switcher>
  </div>
</div>

<crm-spinner *ngIf="loading$ | async"></crm-spinner>

<crm-dashboard-add></crm-dashboard-add>

<crm-dashboard-widget
  [widgetData]="widgetData"
  [loading]="loading"
  [columns]="columns"
  [groupOptions]="groupOptions"
  (reload)="loadTasks()"
  (onScrollEnd)="onScrollEnd()"
>
  <ng-container *ngIf="tasks?.length">
    <crm-table
      *ngIf="!selectedGroup"
      name="tasks-widget"
      [isMinTable]="true"
      [values]="tasks"
      [columns]="selectedColumns"
      [loading]="loading"
      [paginationLoading]="paginationLoading"
      styleClass="sticky-header"
    >
      <ng-template #rowTemplate let-task="item">
        <ng-container *ngIf="toTask(task) as task">
          <tr>
            <td>
              <a routerLink="/task/card/{{ task.id }}">
                {{ task.name }}
              </a>
            </td>
            <td *ngIf="isSelectColumn('date')">
              <p>{{ task.created | date: 'dd.MM.yyyy' }}</p>
            </td>
            <td *ngIf="isSelectColumn('client')">
              <div class="flex flex-column">
                <a routerLink="/user/{{ task.createdBy?.id }}">{{ task.createdBy?.name }}</a>
                <small *ngFor="let client of task.createdBy?.clients">
                  <a routerLink="/client/card/{{ client.id }}">{{ client.name }}</a>
                </small>
              </div>
            </td>
            <td *ngIf="isSelectColumn('template')">
              {{ task.template?.name }}
            </td>
            <td *ngIf="isSelectColumn('status')">
              <p [ngStyle]="{ color: task.status?.type | taskColor }">
                {{ task.status?.name | uppercase }}
              </p>
            </td>
            <td *ngIf="isSelectColumn('tag')">
              <ng-container *ngFor="let tag of task.tags; let index = index">
                {{ tag.tagTemplate.name }}<span *ngIf="index + 1 !== task?.tags?.length">, </span>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </crm-table>

    <crm-table
      *ngIf="selectedGroup"
      name="tasks-widget.group"
      [isMinTable]="true"
      [values]="grouped"
      [loading]="loading"
      styleClass="borderless-table"
    >
      <ng-template #rowTemplate let-group="item">
        <tr>
          <td class="fw-bold">
            <a class="cursor-pointer" (click)="navigateToList(group)">
              {{ group?.name }}
            </a>
          </td>
          <td class="fw-bold text-right">
            {{ group.count | number }}
          </td>
        </tr>
      </ng-template>
    </crm-table>
  </ng-container>

  <p *ngIf="!loading && !tasks?.length" class="font-medium text-lg">Нет заявок</p>

  <ng-container content-button-list>
    <a
      pButton
      label="в список ({{ count || 0 | number }})"
      class="p-button-round p-button-text p-button-sm p-button-info -ml-3 mt-2"
      icon="pi pi-external-link"
      (click)="navigateToList()"
    ></a>
  </ng-container>
</crm-dashboard-widget>

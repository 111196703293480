import { Injectable } from '@angular/core';
import { DashboardFindDto } from '@zaoblako/shared/modules/dashboard/dtos/dashboard/dashboard.find.dto';
import { WidgetFindDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/widget.find.dto';
import { WidgetFormDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/widget.form.dto';
import { ConfirmDialogService } from '@zaoblako/web/core/services/confirm-dialog.service';
import { DashboardService } from '@zaoblako/web/core/services/dashboard/dashboard.service';
import { StoreService } from '@zaoblako/web/core/services/store.service';
import { DashboardState } from '@zaoblako/web/modules/dashboard/components/dashboard/models/dashboard-state';
import { dashboardInitialState } from '@zaoblako/web/modules/dashboard/components/dashboard/state/dashboard-initial-state';
import { DashboardWidgetNamePipe } from '@zaoblako/web/shared/pipes/dashboard-widget-name.pipe';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardStateService extends StoreService<DashboardState> {
  protected state: BehaviorSubject<DashboardState> = new BehaviorSubject(dashboardInitialState);

  public constructor(
    private readonly dashboardService: DashboardService,
    private readonly confirmDialogService: ConfirmDialogService,
    private readonly dashboardWidgetNamePipe: DashboardWidgetNamePipe
  ) {
    super();
  }

  public get getState() {
    return this.state.value;
  }

  public initDashboard(): void {
    this.loadDashboards();
    this.loadCatalog();
  }

  public loadDashboards(): void {
    this.dashboardService.find<DashboardFindDto>().subscribe((dashboards) => {
      const dashboardTabItems = this.getDashboardTabItems(dashboards);
      this.updateState({
        dashboards,
        dashboardTabItems,
        selectDashboard: null,
        activeDashboardTabItem: null
      });
      if (dashboards?.length) {
        this.setActiveDashboard(dashboards.find((dashboard) => dashboard.selected)?.id);
      } else {
        this.updateState({ loading: false });
      }
    });
  }

  public addDashboard(dashboard: DashboardFindDto) {
    const dashboards = this.state.value.dashboards;
    dashboards.push(dashboard);

    const dashboardTabItems = this.getDashboardTabItems(dashboards);

    this.updateState({
      dashboards,
      dashboardTabItems,
      activeDashboardTabItem: dashboardTabItems?.find((item) => String(dashboard.id) === item.id),
      selectDashboard: dashboard
    });
  }

  public updateDashboard(dashboard: DashboardFindDto) {
    const state = this.state.value;
    const dashboards = state.dashboards?.filter((d) => d.id !== dashboard.id);
    dashboards.push(dashboard);

    const dashboardTabItems = this.getDashboardTabItems(dashboards);

    this.updateState({
      dashboards,
      dashboardTabItems,
      activeDashboardTabItem: dashboardTabItems?.find((item) => String(dashboard.id) === item.id),
      selectDashboard: dashboard
    });
  }

  public deleteDashboard(id: number) {
    let state = this.state.value;
    const dashboard = state.dashboards?.find((_dashboard) => _dashboard.id === id);
    if (dashboard) {
      this.updateState({
        dashboards: state.dashboards?.filter((_dashboard) => _dashboard.id !== dashboard.id),
        dashboardTabItems: state.dashboardTabItems?.filter((_dashboard) => _dashboard.id !== String(dashboard.id))
      });
      state = { ...this.state.value };

      if (state.selectDashboard?.id === dashboard.id) {
        if (state.dashboards?.length) {
          this.setActiveDashboard(state.dashboards[0].id);
        } else {
          this.updateState({
            selectDashboard: null,
            activeDashboardTabItem: null
          });
        }
      }
    }
  }

  public setActiveDashboard(id: number) {
    if (id !== this.state.value.selectDashboard?.id) {
      this.updateState({ loading: true });

      this.dashboardService.findById<DashboardFindDto>(id).subscribe((dashboard) => {
        if (id !== this.state.value.dashboards?.find((d) => d.selected)?.id) {
          this.dashboardService.selectDashboard(id).subscribe(() => {
            const dashboards = [...this.state.value.dashboards];
            dashboards.forEach((d) => (d.selected = d.id === id));
            this.updateState({
              dashboards
            });
          });
        }

        dashboard.widgets.forEach((widget) => {
          if (!widget.filter) {
            widget.filter = {};
          }
        });

        this.updateState({
          selectDashboard: dashboard,
          activeDashboardTabItem: this.state.value.dashboardTabItems?.find((item) => String(dashboard.id) === item.id),
          loading: false
        });
      });
    } else {
      this.updateState({ loading: false });
    }
  }

  public addWidget(createdWidget: WidgetFindDto) {
    const selectDashboard = { ...this.state.value.selectDashboard };
    if (!Array.isArray(selectDashboard.widgets)) {
      selectDashboard.widgets = [];
    }
    selectDashboard.widgets = selectDashboard.widgets.concat(createdWidget);
    this.updateState({ selectDashboard });
  }

  public updateWidget(dashboardId: number, widget: WidgetFormDto): Observable<WidgetFindDto> {
    const selectDashboard = { ...this.state.value.selectDashboard };
    return this.dashboardService.updateWidget(dashboardId, widget).pipe(
      tap(() => {
        this.updateState({ selectDashboard });
      })
    );
  }

  public deleteWidget(widget: WidgetFindDto) {
    this.confirmDialogService.confirm({
      message: `Вы действительно хотите удалить виджет "${this.dashboardWidgetNamePipe.transform(widget.type)}"?`,
      accept: () => {
        const selectDashboard = { ...this.state.value.selectDashboard };
        selectDashboard.widgets = selectDashboard.widgets.filter((sd) => sd.id !== widget.id);
        this.dashboardService.deleteWidget(this.state.value.selectDashboard?.id, widget.id).subscribe(() => {
          this.updateState({ selectDashboard });
        });
      }
    });
  }

  public changeOrder() {
    const widgets: WidgetFindDto[] = [...this.getState.selectDashboard.widgets];
    widgets.forEach((w, index) => {
      w.position = index + 1;
    });

    this.dashboardService
      .updateWidgetPositions({ items: widgets.map((w) => ({ id: w.id, position: w.position })) })
      .subscribe();
    this.updateState((state) => ({
      selectDashboard: {
        ...state.selectDashboard,
        widgets
      }
    }));
  }

  public setIsShowDialogCreateDashboard(isShowDialogCreateDashboard: boolean) {
    this.updateState({ isShowDialogCreateDashboard });
  }

  private getDashboardTabItems(dashboards: DashboardFindDto[]): MenuItem[] {
    let items: MenuItem[] =
      dashboards?.map(
        (dashboard) =>
          <MenuItem>{
            label: dashboard.name,
            id: String(dashboard.id),
            state: {
              order: dashboard.order
            },
            command: (event) => {
              this.setActiveDashboard(Number(event.item.id));
            }
          }
      ) || [];
    items.push({
      label: '+',
      id: '+',
      state: {
        order: dashboards.length
      },
      command: () => {
        const dashboardTabItems = this.state.value.dashboardTabItems.map((item) => ({ ...item }));
        const activeDashboardTabItem = dashboardTabItems.find(
          (item) => this.state.value.activeDashboardTabItem?.id === item.id
        );
        this.updateState({
          isShowDialogCreateDashboard: true,
          dashboardTabItems,
          activeDashboardTabItem
        });
      }
    });
    return items;
  }

  private loadCatalog() {
    this.dashboardService.tasksWidgetFilters().subscribe({
      next: (filters) => {
        this.updateState({
          filters
        });
      }
    });
  }
}

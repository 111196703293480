import { Injectable } from '@angular/core';
import { DateRangeQueryDto } from '@zaoblako/shared/common/dtos/date.range.query.dto';
import { DashboardFiltersDto } from '@zaoblako/shared/modules/dashboard/dtos/dashboard/dashboard.filters.dto';
import { AvailableWidgetTreeDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/available.widget.tree.dto';
import { WidgetContractsFindDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/contracts/widget.contracts.find.dto';
import { WidgetContractsQueryDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/contracts/widget.contracts.query.dto';
import { WidgetTasksFindDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/tasks/widget.tasks.find.dto';
import { WidgetTasksQueryDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/tasks/widget.tasks.query.dto';
import { WidgetFindDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/widget.find.dto';
import { WidgetFormDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/widget.form.dto';
import { WidgetUpdatePositionsDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/widget.update.positions.dto';
import { ContragentRevenuesWidgetFindDto } from '@zaoblako/shared/modules/document/dtos/contragent.revenues.widget.find.dto';
import { PaymentsWidgetFindDto } from '@zaoblako/shared/modules/document/dtos/payments.widget.find.dto';
import { WorkloadWidgetFindDto } from '@zaoblako/shared/modules/location/dtos/room/workload.widget.find.dto';
import { ReportWidgetFindDto } from '@zaoblako/shared/modules/report/dtos/dashboard/report.widget.find.dto';
import { TrafficWidgetFindDto } from '@zaoblako/shared/modules/report/dtos/traffic/traffic.widget.find.dto';
import { TrafficWidgetReportQueryDto } from '@zaoblako/shared/modules/report/dtos/traffic/traffic.widget.report.query.dto';
import { BaseService } from '@zaoblako/web/core/services/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService {
  protected baseUrl = '/dashboard';

  public selectDashboard(dashboardId: number): Observable<null> {
    return this.http.get<null>(`${this.baseUrl}/${dashboardId}/select`);
  }

  public addWidget(dashboardId: number, widget: WidgetFormDto): Observable<WidgetFindDto> {
    return this.http.post<WidgetFindDto>(`${this.baseUrl}/${dashboardId}/widget`, widget);
  }

  public updateWidget(dashboardId: number, widget: WidgetFormDto): Observable<WidgetFindDto> {
    return this.http.put<WidgetFindDto>(`${this.baseUrl}/${dashboardId}/widget/${widget['id']}`, widget);
  }

  public deleteWidget(dashboardId: number, id: number): Observable<WidgetFindDto> {
    return this.http.delete<WidgetFindDto>(`${this.baseUrl}/${dashboardId}/widget/${id}`);
  }

  public updateWidgetPositions(body: WidgetUpdatePositionsDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/widget/update-positions`, body);
  }

  public getWidgetsList(): Observable<AvailableWidgetTreeDto[]> {
    return this.http.get<AvailableWidgetTreeDto[]>(`${this.baseUrl}/widget/list`);
  }

  public tasksWidget(queryParams: WidgetTasksQueryDto): Observable<WidgetTasksFindDto> {
    return this.http.get<WidgetTasksFindDto>(`${this.baseUrl}/tasks/tasks`, {
      params: this.getParams(queryParams)
    });
  }

  public contractsWidget(queryParams: WidgetContractsQueryDto): Observable<WidgetContractsFindDto> {
    return this.http.get<WidgetContractsFindDto>(`${this.baseUrl}/contracts/contracts`, {
      params: this.getParams(queryParams)
    });
  }

  public tasksWidgetFilters(): Observable<DashboardFiltersDto> {
    return this.http.get<DashboardFiltersDto>(`${this.baseUrl}/filters`);
  }

  public trafficWidget(queryParams: TrafficWidgetReportQueryDto): Observable<TrafficWidgetFindDto> {
    return this.http.get<TrafficWidgetFindDto>(`/${this.baseUrl}/statistics/traffic`, {
      params: this.getParams(queryParams)
    });
  }

  public workloadWidget(): Observable<WorkloadWidgetFindDto> {
    return this.http.get<WorkloadWidgetFindDto>(`/${this.baseUrl}/statistics/workload`);
  }

  public paymentsWidget(queryParams: DateRangeQueryDto): Observable<PaymentsWidgetFindDto> {
    return this.http.get<PaymentsWidgetFindDto>(`/${this.baseUrl}/statistics/payments`, {
      params: this.getParams(queryParams)
    });
  }

  public contragentRevenuesWidget(queryParams: DateRangeQueryDto): Observable<ContragentRevenuesWidgetFindDto> {
    return this.http.get<ContragentRevenuesWidgetFindDto>(`/${this.baseUrl}/statistics/contragent-revenues`, {
      params: this.getParams(queryParams)
    });
  }

  public reports(): Observable<ReportWidgetFindDto[]> {
    return this.http.get<ReportWidgetFindDto[]>(`${this.baseUrl}/statistics/reports`);
  }
}

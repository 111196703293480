import { BaseDto } from '@zaoblako/shared/common/dtos/base.dto';
import { Expose } from 'class-transformer';
import { IsString, IsNotEmpty } from 'class-validator';

/** Dashboard create and update requests body */
@Expose()
export class DashboardFormDto extends BaseDto {
  /** Dashboard display name */
  @IsNotEmpty({
    message: 'Имя вкладки должно содержать не менее двух символов',
  })
  @IsString({
    message: 'Имя вкладки должно быть строкой',
  })
  @Expose()
  public name: string;
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterEmpty'
})
export class FilterEmptyPipe implements PipeTransform {
  public transform<T>(items: readonly T[], key: string): T[] {
    return items.filter((item) => item[key]);
  }
}

<ng-container *ngIf="!loading && !widgetLoading">
  <div class="p-fluid">
    <div class="field">
      <label class="font-medium required-field">Тип</label>
      <crm-widget-types-single-select
        [widgetTypes]="widgetTypes"
        [groupsDisabled]="true"
        [(selectedWidgetType)]="selectedWidgetType"
        placeholder="Тип виджета"
        appendTo="body"
      ></crm-widget-types-single-select>
    </div>
  </div>

  <div class="mt-3">
    <button
      pButton
      label="Добавить"
      class="p-button-success"
      [disabled]="!selectedWidgetType"
      (click)="createWidget()"
    ></button>
  </div>
</ng-container>

<crm-spinner *ngIf="loading || widgetLoading" top=""></crm-spinner>

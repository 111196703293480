<span class="p-float-label" *ngIf="label; else dropdown">
  <ng-container *ngTemplateOutlet="dropdown"></ng-container>
  <label [for]="inputId">{{ label }}</label>
</span>

<ng-template #dropdown>
  <p-treeSelect
    #treeSelect
    [options]="_options | orderBy: sort"
    [(ngModel)]="selectionMode === 'single' ? _selectedItem : _selectedItems"
    (ngModelChange)="selectedItemsOptions.updateOn === 'default' ? onChangeValue($event) : undefined"
    (onHide)="onPanelHide()"
    (onShow)="panelShow.emit(); openedChange.emit(true)"
    [placeholder]="label ? '' : placeholder"
    [inputId]="inputId"
    [selectionMode]="selectionMode"
    class="w-full {{ class }}"
    [disabled]="disabled"
    [appendTo]="appendTo"
    [emptyMessage]="emptyMessage"
    [scrollHeight]="scrollHeight"
    [metaKeySelection]="metaKeySelection"
    [display]="display"
  >
  </p-treeSelect>
</ng-template>

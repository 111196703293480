import { Pipe, PipeTransform } from '@angular/core';
import { TaskStatusTypeEnum } from '@zaoblako/shared/modules/task/enums/task.status.type.enum';


const taskColors: Partial<Record<TaskStatusTypeEnum, string>> = {
  [TaskStatusTypeEnum.TASK_STATUS_TYPE_OPENED]: '#7dab21',
  [TaskStatusTypeEnum.TASK_STATUS_TYPE_ACCEPTED]: '#6e906e',
  [TaskStatusTypeEnum.TASK_STATUS_TYPE_COMPLETED]: '#969696',
  [TaskStatusTypeEnum.TASK_STATUS_TYPE_CANCELED]: '#cd5c5c'
};

@Pipe({
  name: 'taskColor'
})
export class TaskColorPipe implements PipeTransform {
  public transform(taskStatus: TaskStatusTypeEnum): string {
    return taskColors[taskStatus] || '';
  }
}

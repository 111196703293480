<crm-dashboard-widget [widgetData]="widgetData" [loading]="loading" (reload)="load()">
  <div class="flex flex-wrap justify-content-between align-items-start">
    <div class="text-2xl flex align-items-center gap-2">
      <i class="pi pi-chart-bar color-teal text-2xl"></i>
      <ng-container *ngIf="!loading; else statSkeleton">
        <p class="fw-semibold">
          {{ result.active | number }} ({{ (100 * result.active) / result.all | number: '.0-0' }}%) м²
        </p>
      </ng-container>
    </div>
  </div>

  <p class="mt-2 -ml-3">
    <button
      pButton
      label="Подробнее..."
      class="p-button-text p-button-sm p-button-secondary"
      routerLink="/location/free"
    ></button>
  </p>

  <ng-template #statSkeleton>
    <p-skeleton width="120px" height="25px"></p-skeleton>
  </ng-template>
</crm-dashboard-widget>

import { Pipe, PipeTransform } from '@angular/core';
import { DashboardWidgetTypeEnum } from '@zaoblako/shared/modules/dashboard/enums/dashboard.widget.type.enum';

const dashboardWidgetNames: Record<DashboardWidgetTypeEnum, string> = {
  [DashboardWidgetTypeEnum.TRAFFIC]: 'Трафик', // Трафик
  [DashboardWidgetTypeEnum.WORKLOAD]: 'Загруженность', // Загруженность
  [DashboardWidgetTypeEnum.PAYMENTS]: 'Платежи', // Платежи
  [DashboardWidgetTypeEnum.OUTGOING_PAYMENTS]: 'Исходящие платежи', // Исходящие платежи
  [DashboardWidgetTypeEnum.TENANT_REVENUE]: 'Выручка арендаторов', // Выручка арендаторов
  [DashboardWidgetTypeEnum.TASKS]: 'Заявки', // Заявки
  [DashboardWidgetTypeEnum.RENTAL_TASKS]: 'Заявки на аренду', // Заявки на аренду
  [DashboardWidgetTypeEnum.FOR_MEETING]: 'Для совещания', // Для совещания
  [DashboardWidgetTypeEnum.STAGES_DEALS]: 'Этапы сделок по аренде', // Этапы сделок по аренде
  [DashboardWidgetTypeEnum.TENANT_INDICES]: 'Индексы арендаторов', // Индексы арендаторов
  [DashboardWidgetTypeEnum.EXPIRING_CONTRACTS]: 'Истекающие договоры(а)', // Истекающие договоры(а)
  [DashboardWidgetTypeEnum.USER_ACTIVITY]: 'Активность пользователей', // Активность пользователей
  [DashboardWidgetTypeEnum.LATEST_COMMENTS]: 'Последние комментарии', // Последние комментарии
  [DashboardWidgetTypeEnum.CONTRACTS]: 'Договоры(а)', // Договоры(а)
  [DashboardWidgetTypeEnum.REPORTS]: 'Отчёты' // Отчёты
};

@Pipe({
  name: 'dashboardWidgetName'
})
export class DashboardWidgetNamePipe implements PipeTransform {
  public transform(type: DashboardWidgetTypeEnum): string {
    return dashboardWidgetNames[type] || '';
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContractParamsStatusEnum } from '@zaoblako/shared/modules/contract/enums/contract.params.status.enum';
import { DashboardFiltersDto } from '@zaoblako/shared/modules/dashboard/dtos/dashboard/dashboard.filters.dto';
import { WidgetFindDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/widget.find.dto';
import { DashboardWidgetTypeEnum } from '@zaoblako/shared/modules/dashboard/enums/dashboard.widget.type.enum';
import { SortDirectionEnum } from '@zaoblako/shared/modules/dashboard/enums/sort.direction.enum';
import { PeriodEnum } from '@zaoblako/shared/modules/report/enums/period.enum';
import { CrmTableColumn } from '@zaoblako/web/core/models/crm-table-column.interface';
import { ErrorService } from '@zaoblako/web/core/services/error.service';
import { DashboardStateService } from '@zaoblako/web/modules/dashboard/components/dashboard/services/dashboard-state.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'crm-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardWidgetComponent implements OnInit {
  @Input()
  public loading: boolean;

  @Input()
  public widgetData: WidgetFindDto;

  @Output()
  public reload = new EventEmitter<void>();

  @Output()
  public onScrollEnd = new EventEmitter<void>();

  public WidgetType = DashboardWidgetTypeEnum;
  public ContractStatus = Object.values(ContractParamsStatusEnum);

  public filters$: Observable<DashboardFiltersDto>;

  public showFilterPeriodFromWidgetType: DashboardWidgetTypeEnum[] = [
    DashboardWidgetTypeEnum.TASKS,
    DashboardWidgetTypeEnum.TRAFFIC,
    DashboardWidgetTypeEnum.PAYMENTS,
    DashboardWidgetTypeEnum.TENANT_REVENUE
  ];

  public showFilterStatusFromWidgetType: DashboardWidgetTypeEnum[] = [DashboardWidgetTypeEnum.TASKS];

  public showFilterContractStatusFromWidgetType: DashboardWidgetTypeEnum[] = [DashboardWidgetTypeEnum.CONTRACTS];

  public showFilterTaskTemplateFromWidgetType: DashboardWidgetTypeEnum[] = [DashboardWidgetTypeEnum.TASKS];

  public showFilterResponsibleFromWidgetType: DashboardWidgetTypeEnum[] = [DashboardWidgetTypeEnum.TASKS];

  public showFilterAgreementFromWidgetType: DashboardWidgetTypeEnum[] = [DashboardWidgetTypeEnum.TASKS];

  public showFilterTagFromWidgetType: DashboardWidgetTypeEnum[] = [DashboardWidgetTypeEnum.TASKS];

  public showFilterIsMyApprovalFromWidgetType: DashboardWidgetTypeEnum[] = [DashboardWidgetTypeEnum.TASKS];

  public showFilterIgnorePeriodFromWidgetType: DashboardWidgetTypeEnum[] = [DashboardWidgetTypeEnum.TASKS];

  public showFilterDaysToEndFromWidgetType: DashboardWidgetTypeEnum[] = [DashboardWidgetTypeEnum.CONTRACTS];

  public showFilterColumnFromWidgetType: DashboardWidgetTypeEnum[] = [
    DashboardWidgetTypeEnum.CONTRACTS,
    DashboardWidgetTypeEnum.TASKS
  ];

  public showFilterGroupByFromWidgetType: DashboardWidgetTypeEnum[] = [DashboardWidgetTypeEnum.TASKS];

  public showFilterSortFromWidgetType: DashboardWidgetTypeEnum[] = [
    DashboardWidgetTypeEnum.CONTRACTS,
    DashboardWidgetTypeEnum.TASKS
  ];

  public readonly sizeOptions: { label: string; value: number }[] = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 }
  ];

  public readonly periodOptions: { label: string; value: PeriodEnum }[] = [
    { label: 'День', value: PeriodEnum.DAY },
    { label: 'Неделя', value: PeriodEnum.WEEK },
    { label: 'Месяц', value: PeriodEnum.MONTH }
  ];

  @Input() public columns: CrmTableColumn[] = [];
  public selectedColumns: CrmTableColumn[] = [];

  @Input() public groupOptions: { label: string; value: any }[] = [];
  public selectedGroupOption: { label: string; value: any } = null;

  public sortOptions: { id: number; label: string; name: string; direction: SortDirectionEnum }[] = [];
  public selectedSortOption: { id: number; label: string; name: string; direction: SortDirectionEnum } = null;

  public get SortDirectionEnum() {
    return SortDirectionEnum;
  }

  public constructor(
    private readonly dashboardStateService: DashboardStateService,
    private readonly errorService: ErrorService
  ) {}

  public ngOnInit(): void {
    this.filters$ = this.dashboardStateService.select((state) => state.filters);
    if (!Array.isArray(this.widgetData.selectedColumns)) {
      this.widgetData.selectedColumns = this.columns.filter((col) => col.required).map((col) => col.name);
    }
    this.selectedColumns = this.columns.filter(
      (column) => this.widgetData.selectedColumns.includes(column.name) || column.required
    );
    this.selectedGroupOption = this.groupOptions.find((group) => this.widgetData.groupBy === group.value);

    if (this.columns?.length) {
      this.columns.forEach((col, index) => {
        if (col.name?.length) {
          this.sortOptions.push({
            id: index * 2,
            label: col.label,
            name: col.name,
            direction: SortDirectionEnum.ASC
          });
          this.sortOptions.push({
            id: index * 2 + 1,
            label: col.label,
            name: col.name,
            direction: SortDirectionEnum.DESC
          });
        }
      });

      this.selectedSortOption = this.sortOptions.find(
        (sort) => this.widgetData.sortColumn === sort.name && this.widgetData.sortDirection === sort.direction
      );
    }
  }

  public deleteWidget(): void {
    this.dashboardStateService.deleteWidget(this.widgetData);
  }

  public updateWidget(isReload: boolean = true): void {
    this.widgetData.selectedColumns = this.selectedColumns.map((column) => column.name);
    this.widgetData.groupBy = this.selectedGroupOption?.value || null;
    this.widgetData.sortColumn = this.selectedSortOption?.name || null;
    this.widgetData.sortDirection = this.selectedSortOption?.direction || null;

    if (isReload) {
      this.dashboardStateService
        .updateWidget(this.dashboardStateService.getState.selectDashboard?.id, this.widgetData)
        .subscribe(() => {
          this.reload.emit();
        });
    } else {
      this.dashboardStateService
        .updateWidget(this.dashboardStateService.getState.selectDashboard?.id, this.widgetData)
        .subscribe();
    }
  }

  public scroll(target: HTMLElement): void {
    if (target.offsetHeight + Math.ceil(target.scrollTop) >= target.scrollHeight) {
      this.onScrollEnd.emit();
    }
  }

  public changeWidgetDataWidth(width: number) {
    if (this.widgetData.selectedColumns?.length > width * 4) {
      this.errorService.addCustomError('Ошибка', 'Чтобы уменьшить ширину виджета, уменьшите количество колонок');
    } else {
      this.widgetData.width = width;
    }
  }

  public selectedFieldsChange(fields: CrmTableColumn[]) {
    if (fields?.length > this.widgetData.width * 4) {
      this.errorService.addCustomError('Ошибка', 'Чтобы показывать больше колонок, увеличьте ширину виджета');
      this.selectedColumns = fields.slice(0, this.widgetData.width * 4);
    } else {
      this.selectedColumns = fields;
    }
  }

  public toSortItem(item: any): { id: number; label: string; name: string; direction: SortDirectionEnum } {
    return item as { id: number; label: string; name: string; direction: SortDirectionEnum };
  }
}

/** All available dashboard widgets types */
export enum DashboardWidgetTypeEnum {
  /** Трафик */
  TRAFFIC = 'TRAFFIC',
  /** Загруженность */
  WORKLOAD = 'WORKLOAD',
  /** Платежи */
  PAYMENTS = 'PAYMENTS',
  /** Исходящие платежи */
  OUTGOING_PAYMENTS = 'OUTGOING_PAYMENTS',
  /** Выручка арендаторов */
  TENANT_REVENUE = 'TENANT_REVENUE',
  /** Заявки */
  TASKS = 'TASKS',
  /** Заявки на аренду */
  RENTAL_TASKS = 'RENTAL_TASKS',
  /** Для совещания */
  FOR_MEETING = 'FOR_MEETING',
  /** Этапы сделок по аренде */
  STAGES_DEALS = 'STAGES_DEALS',
  /** Индексы арендаторов */
  TENANT_INDICES = 'TENANT_INDICES',
  /** Истекающие договоры */
  EXPIRING_CONTRACTS = 'EXPIRING_CONTRACTS',
  /** Активность пользователей */
  USER_ACTIVITY = 'USER_ACTIVITY',
  /** Последние комментарии */
  LATEST_COMMENTS = 'LATEST_COMMENTS',
  /** Договоры */
  CONTRACTS = 'CONTRACTS',
  /** Отчёты */
  REPORTS = 'REPORTS',
}

export const DashboardWidgetTypeTranslateEnum: Record<DashboardWidgetTypeEnum, string> = {
  [DashboardWidgetTypeEnum.TRAFFIC]: 'Трафик',
  [DashboardWidgetTypeEnum.WORKLOAD]: 'Загруженность',
  [DashboardWidgetTypeEnum.PAYMENTS]: 'Платежи',
  [DashboardWidgetTypeEnum.OUTGOING_PAYMENTS]: 'Исходящие платежи',
  [DashboardWidgetTypeEnum.TENANT_REVENUE]: 'Выручка арендаторов',
  [DashboardWidgetTypeEnum.TASKS]: 'Заявки',
  [DashboardWidgetTypeEnum.RENTAL_TASKS]: 'Заявки на аренду',
  [DashboardWidgetTypeEnum.FOR_MEETING]: 'Для совещания',
  [DashboardWidgetTypeEnum.STAGES_DEALS]: 'Этапы сделок по аренде',
  [DashboardWidgetTypeEnum.TENANT_INDICES]: 'Индексы арендаторов',
  [DashboardWidgetTypeEnum.EXPIRING_CONTRACTS]: 'Истекающие договоры',
  [DashboardWidgetTypeEnum.USER_ACTIVITY]: 'Активность пользователей',
  [DashboardWidgetTypeEnum.LATEST_COMMENTS]: 'Последние комментарии',
  [DashboardWidgetTypeEnum.CONTRACTS]: 'Договоры',
  [DashboardWidgetTypeEnum.REPORTS]: 'Отчёты',
};

import { Pipe, PipeTransform } from '@angular/core';
import { ContractParamsStatusEnum } from '@zaoblako/shared/modules/contract/enums/contract.params.status.enum';

const contractParamsStatusNames: Record<ContractParamsStatusEnum, string> = {
  [ContractParamsStatusEnum.ACTIVE]: 'Активный',
  [ContractParamsStatusEnum.DISSOLVED]: 'Расторгнут',
  [ContractParamsStatusEnum.EXPIRING]: 'Истекающий'
};

@Pipe({
  name: 'contractParamsStatusName'
})
export class ContractParamsStatusNamePipe implements PipeTransform {
  public transform(status: ContractParamsStatusEnum): string {
    return contractParamsStatusNames[status] || '';
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { WidgetFindDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/widget.find.dto';
import { WorkloadWidgetFindDto } from '@zaoblako/shared/modules/location/dtos/room/workload.widget.find.dto';
import { DashboardService } from '@zaoblako/web/core/services/dashboard/dashboard.service';

@Component({
  selector: 'crm-widget-workload',
  templateUrl: './widget-workload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetWorkloadComponent implements OnInit {
  @Input() public widgetData: WidgetFindDto;

  public loading = false;
  public result: WorkloadWidgetFindDto;

  public constructor(private readonly dashboardService: DashboardService, private readonly cdRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.load();
  }

  public load() {
    this.loading = true;
    this.cdRef.markForCheck();

    this.dashboardService.workloadWidget().subscribe({
      next: (result) => {
        this.result = result;
        this.loading = false;
        this.cdRef.markForCheck();
      }
    });
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'crm-input-text',
  templateUrl: './input-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputTextComponent {
  @ViewChild('input', { static: false }) public input: ElementRef;

  @Input() public value: string;
  @Output() public valueChange = new EventEmitter<string>();

  @Output() public onFocus = new EventEmitter<any>();
  @Output() public onBlur = new EventEmitter<any>();

  @Input() public isError = false;

  @Input() public type = 'text';
  @Input() public class: string;
  @Input() public style: string;
  @Input() public placeholder = '';
  @Input() public name: string;
  @Input() public disabled: boolean;
  @Input() public autocomplete: string;
  @Input() public minLength: number;
  @Input() public maxLength: number;

  @Input() public isInputIconLeft = false;
  @Input() public showClear = false;
  @Input() public fontSizeIconClear = 1;
  @Input() public icon: string;

  public constructor(private readonly cdRef: ChangeDetectorRef) {}

  public changeText(text: string) {
    if (text === undefined || text?.trim() === '') {
      this.value = null;
    } else {
      this.value = text.replace(new RegExp('\u0000+', 'im'), '');
    }
    this.valueChange.emit(this.value);
    this.cdRef.markForCheck();
  }

  public clearValue() {
    this.value = null;
    this.valueChange.emit(this.value);
  }
}

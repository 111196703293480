import { Component, Input } from '@angular/core';

@Component({
  selector: 'crm-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent {
  @Input() public text = 'Загрузка...';
  @Input() public top = 'mt-6 pt-6';
}

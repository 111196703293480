<div class="p-fluid">
  <div class="field">
    <label class="font-medium required-field">Название</label>
    <small *ngIf="dashboard.name?.length >= 35" class="p-error">Ограничение до 35 символов</small>

    <crm-input-text
      [(value)]="dashboard.name"
      [showClear]="true"
      class="w-full"
      [isError]="$any(errors?.name)"
    ></crm-input-text>
    <small *ngIf="errors?.name" class="p-error">
      {{ errors.name | join }}
    </small>
  </div>
</div>

<div class="mt-3">
  <div class="flex flex-wrap justify-content-between">
    <button pButton [label]="saveButtonLabel" class="p-button-success mr-2" (click)="onSave(dashboard)"></button>
    <button
      *ngIf="canDelete"
      pButton
      label="Удалить"
      class="p-button-danger p-button-text"
      (click)="delete.emit(dashboard)"
    ></button>
  </div>
</div>

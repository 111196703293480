import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { WidgetFindDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/widget.find.dto';
import { ReportWidgetFindDto } from '@zaoblako/shared/modules/report/dtos/dashboard/report.widget.find.dto';
import { DashboardService } from '@zaoblako/web/core/services/dashboard/dashboard.service';

@Component({
  selector: 'crm-widget-reports',
  templateUrl: './widget-reports.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetReportsComponent implements OnInit {
  @Input() public widgetData: WidgetFindDto;

  public loading = false;
  public items: ReportWidgetFindDto[] = [];

  public constructor(private readonly dashboardService: DashboardService, private readonly cdRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.load();
  }

  public load() {
    this.loading = true;
    this.cdRef.markForCheck();

    this.dashboardService.reports().subscribe({
      next: (items) => {
        this.items = items;
        this.loading = false;
        this.cdRef.markForCheck();
      }
    });
  }

  public toItem(item: any): ReportWidgetFindDto {
    return item as ReportWidgetFindDto;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardFindDto } from '@zaoblako/shared/modules/dashboard/dtos/dashboard/dashboard.find.dto';
import { ConfirmDialogService } from '@zaoblako/web/core/services/confirm-dialog.service';
import { DashboardService } from '@zaoblako/web/core/services/dashboard/dashboard.service';
import { ErrorService } from '@zaoblako/web/core/services/error.service';
import { DashboardAddWidgetComponent } from '@zaoblako/web/modules/dashboard/components/dashboard/components/dashboard-add-widget/dashboard-add-widget.component';
import { DashboardStateService } from '@zaoblako/web/modules/dashboard/components/dashboard/services/dashboard-state.service';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import * as Sortable from 'sortablejs';

@Component({
  selector: 'crm-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public currentDate = new Date();
  public loading$: Observable<boolean>;
  public selectDashboard$: Observable<DashboardFindDto>;
  public dashboardTabItems$: Observable<MenuItem[]>;
  public activeDashboardTabItem$: Observable<MenuItem>;

  public loading = false;
  public ref: DynamicDialogRef;

  public optionsSortable: Sortable.Options;

  public constructor(
    private readonly dashboardStateService: DashboardStateService,
    private readonly dialogService: DialogService,
    private readonly confirmService: ConfirmDialogService,
    private readonly dashboardService: DashboardService,
    private readonly errorService: ErrorService
  ) {}

  public ngOnInit() {
    this.loading$ = this.dashboardStateService.select((state) => state.loading);
    this.selectDashboard$ = this.dashboardStateService.select((state) => state.selectDashboard);
    this.dashboardTabItems$ = this.dashboardStateService.select((state) => state.dashboardTabItems);
    this.activeDashboardTabItem$ = this.dashboardStateService.select((state) => state.activeDashboardTabItem);

    this.optionsSortable = {
      handle: '.isDrag',
      onUpdate: () => {
        this.dashboardStateService.changeOrder();
      }
    };

    this.dashboardStateService.initDashboard();
  }

  public ngOnDestroy() {
    this.ref?.destroy();
  }

  public openDialogCreate() {
    this.ref = this.dialogService.open(DashboardAddWidgetComponent, {
      header: 'Добавить виджет',
      contentStyle: { overflow: 'hidden' },
      styleClass: 'xl:w-3 lg:w-5 md:w-8 sm:w-11 w-full',
      baseZIndex: 10000
    });
  }

  public openDialogDelete(tab: MenuItem): void {
    this.confirmService.confirm({
      message: `Вы действительно хотите удалить вкладку "${tab?.label}"?`,
      accept: () => {
        this.loading = true;

        this.dashboardService.deleteById(+tab?.id).subscribe({
          next: () => {
            this.dashboardStateService.deleteDashboard(+tab?.id);
            this.errorService.addSuccessMessage(`Вкладка "${tab?.label}" удалена`);
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        });
      }
    });
  }
}

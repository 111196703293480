import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AvailableWidgetTreeDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/available.widget.tree.dto';
import { AvailableWidgetTypeDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/available.widget.type.dto';
import { WidgetFormDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/widget.form.dto';
import { PeriodEnum } from '@zaoblako/shared/modules/report/enums/period.enum';
import { DashboardService } from '@zaoblako/web/core/services/dashboard/dashboard.service';
import { ErrorService } from '@zaoblako/web/core/services/error.service';
import { DashboardStateService } from '@zaoblako/web/modules/dashboard/components/dashboard/services/dashboard-state.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'crm-dashboard-add-widget',
  templateUrl: './dashboard-add-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardAddWidgetComponent implements OnInit {
  public loading = false;
  public widgetLoading = false;
  public selectedWidgetType: AvailableWidgetTypeDto;
  public widgetTypes: AvailableWidgetTreeDto[];

  public constructor(
    private readonly dashboardService: DashboardService,
    private readonly dashboardStateService: DashboardStateService,
    private readonly errorService: ErrorService,
    private readonly ref: DynamicDialogRef,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.widgetLoading = true;
    this.cdRef.markForCheck();

    this.dashboardService.getWidgetsList().subscribe((result) => {
      this.widgetTypes = result;
      this.widgetLoading = false;
      this.cdRef.markForCheck();
    });
  }

  public createWidget() {
    if (!this.selectedWidgetType) {
      return this.errorService.addCustomError('Не выбран тип');
    }

    this.loading = true;
    this.cdRef.markForCheck();

    const currentMaxPosition = this.dashboardStateService.getState.selectDashboard?.widgets?.length
      ? Math.max(...this.dashboardStateService.getState.selectDashboard?.widgets?.map((widget) => widget.position))
      : 0;

    const widget: WidgetFormDto = {
      type: this.selectedWidgetType?.type,
      position: currentMaxPosition + 1,
      width: 1,
      height: 1,
      filter: {
        period: PeriodEnum.DAY
      }
    };
    this.dashboardService.addWidget(this.dashboardStateService.getState.selectDashboard?.id, widget).subscribe({
      next: (createdWidget) => {
        if (!createdWidget.filter) {
          createdWidget.filter = {
            period: PeriodEnum.DAY
          };
        }
        this.dashboardStateService.addWidget(createdWidget);
        this.loading = false;
        this.cdRef.markForCheck();
        this.ref.close();
      },
      error: () => {
        this.loading = false;
        this.cdRef.markForCheck();
      }
    });
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WidgetFindDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/widget.find.dto';
import { DashboardWidgetTypeEnum } from '@zaoblako/shared/modules/dashboard/enums/dashboard.widget.type.enum';

@Component({
  selector: 'crm-dashboard-widget-switcher',
  templateUrl: './dashboard-widget-switcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardWidgetSwitcherComponent {
  @Input()
  public widget: WidgetFindDto;

  public WidgetType = DashboardWidgetTypeEnum;
}

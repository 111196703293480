import { Component, Input } from '@angular/core';
import { TaskByTypeReportDto } from '@zaoblako/shared/modules/report/dtos/task/task.by.type.report.dto';
import { TaskStatusFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.status.find.dto';
import { TaskStatusTypeEnum } from '@zaoblako/shared/modules/task/enums/task.status.type.enum';
import { AuthService } from '@zaoblako/web/core/services/security/auth.service';
import { DashboardPeriodType } from '@zaoblako/web/core/types/dashboard-period.type';

@Component({
  selector: 'crm-dashboard-task-stats',
  templateUrl: './dashboard-task-stats.component.html'
})
export class DashboardTaskStatsComponent {
  @Input() public taskStats: TaskByTypeReportDto;
  @Input() public activePeriodLabel: string;
  @Input() public activePeriodName: DashboardPeriodType;

  @Input() public statuses: TaskStatusFindDto[];

  public skeletonItems = Array(5).fill(null);

  public get TaskStatusTypeEnum() {
    return TaskStatusTypeEnum;
  }

  public get currentUser() {
    return this.authService.currentUser;
  }

  public get getActiveStatusIds() {
    return (
      this.statuses
        ?.filter((status) =>
          [TaskStatusTypeEnum.TASK_STATUS_TYPE_OPENED, TaskStatusTypeEnum.TASK_STATUS_TYPE_ACCEPTED].includes(
            status.type
          )
        )
        ?.map((status) => status.id) || []
    );
  }

  public get getAcceptStatusIds() {
    return (
      this.statuses
        ?.filter((status) => [TaskStatusTypeEnum.TASK_STATUS_TYPE_ACCEPTED].includes(status.type))
        ?.map((status) => status.id) || []
    );
  }

  public constructor(private readonly authService: AuthService) {}
}

import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'crm-dashboard-reports',
  templateUrl: './dashboard-reports.component.html',
  styleUrls: ['./dashboard-reports.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardReportsComponent {
  public reports: Array<{ name: string; link: string; description: string }> = [
    { name: 'Потенциальные арендаторы', link: '/report/potential', description: 'Отчет по заявкам на аренду' },
    // { name: 'Отчет о выручке', link: '/report/revenue-year', description: 'Отчет о выручке по арендной плате за год' },
    {
      name: 'Арендные ставки',
      link: '/report/rents',
      description: 'Сравнение арендных ставок для помещений по клиентам и договорам за определенный период'
    },
    { name: 'Условия договоров', link: '/report/contract-service', description: 'Отчет по всем условиям договоров' },
    { name: 'Реестр КП', link: '/report/deal', description: 'Реестр коммерческих предложений помещений' },
    { name: 'Новые договоры', link: '/report/new-contract', description: 'Отчет по новым договорам' },
    { name: 'Сроки согласования', link: '/report/terms', description: 'Отчет по исполнению сроков согласования' },
    { name: 'Поступления', link: '/report/receipt', description: 'Отчет по поступлениям товаров и услуг' },
    // { name: 'Расходы', link: '/report/outgoing', description: 'Отчет по расходу денежных средств' },
    { name: 'Начисления', link: '/report/sale', description: 'Отчет по начислениям' },
    { name: 'Реестр ККТ', link: '/report/kkt', description: 'Реестр ККТ арендаторов' },
    { name: 'Дебаркадер', link: '/report/area-occupation', description: 'Отчет о бронировании' },
    // {
    //   name: 'Платежный календарь',
    //   link: '/report/payment-calendar',
    //   description: 'Отображение плановых и фактических доходов и расходов по бюджетным статьям'
    // },
    {
      name: 'Сверка документов',
      link: '/report/autocheck',
      description: 'Сравнение документов из 1С и CRM за выбранный период'
    },
    // {
    //   name: 'Доходы и расходы по контрагентам',
    //   link: '/report/payment-outgoing',
    //   description: 'Доходы и расходы по контрагентам'
    // },
    // { name: 'Доходы и расходы', link: '/report/balance', description: 'Отчет по доходам и расходам' },
    { name: 'Скидки', link: '/report/task-discount', description: 'Отчет по заявкам на скидку' },
    { name: 'Протоколы совещаний', link: '/report/meeting', description: 'Протоколы совещаний' },
    { name: 'Проблемные помещения', link: '/report/problem-room', description: 'Отчет по проблемным помещениям' },
    {
      name: 'Реестр документов к заявкам',
      link: '/report/contract-document',
      description: 'Отчет по реестру документов к заявкам'
    },
    {
      name: 'Выручка арендаторов',
      link: '/report/revenue',
      description: 'Отчет по выручке арендаторов'
    },
    {
      name: 'Доля выручки арендаторов',
      link: '/report/revenue-share',
      description: 'Отчет по долям выручки арендаторов'
    },
    {
      name: 'Структура выручки арендаторов',
      link: '/report/revenue-structure',
      description: 'Отчет по структуре выручке арендаторов'
    }
  ];
}

<span
  *ngIf="icon || showClear; else inputTemplate"
  [class.p-input-icon-left]="isInputIconLeft && icon"
  [class.p-input-icon-right]="!isInputIconLeft && icon"
  class="w-full relative inline-block"
>
  <ng-container *ngIf="!isInputIconLeft">
    <ng-template [ngTemplateOutlet]="clearIconTemplate"></ng-template>
  </ng-container>
  <i *ngIf="icon" [class]="icon"></i>
  <ng-container *ngIf="isInputIconLeft">
    <ng-template [ngTemplateOutlet]="clearIconTemplate"></ng-template>
  </ng-container>
  <ng-template [ngTemplateOutlet]="inputTemplate"></ng-template>

  <ng-template #clearIconTemplate>
    <i
      *ngIf="value && showClear"
      class="
        pi pi-times
        cursor-pointer
        hover:frm-color-blue-light
        absolute
        top-50
        -mt-2
        right-0
        frm-input-text-clear-icon
      "
      [ngStyle]="{
        'font-size.rem': fontSizeIconClear,
        'margin-right.rem': !isInputIconLeft && icon ? 2.5 * fontSizeIconClear : 1
      }"
      (click)="clearValue()"
    ></i>
  </ng-template>
</span>

<ng-template #inputTemplate>
  <input
    #input
    [type]="type"
    pInputText
    [ngModel]="value"
    (ngModelChange)="changeText($event)"
    (focus)="onFocus.emit($event)"
    (blur)="onBlur.emit($event)"
    [class]="class + (isError ? ' ng-invalid ng-dirty' : '')"
    [placeholder]="placeholder"
    [name]="name"
    [style]="style"
    [ngStyle]="{ 'padding-right.rem': showClear && value ? (!isInputIconLeft && icon ? 4 : 2.5) : null }"
    [disabled]="disabled"
    [autocomplete]="autocomplete"
    [minLength]="minLength"
    [maxlength]="maxLength"
  />
</ng-template>

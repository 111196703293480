import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DashboardFindDto } from '@zaoblako/shared/modules/dashboard/dtos/dashboard/dashboard.find.dto';
import { DashboardFormDto } from '@zaoblako/shared/modules/dashboard/dtos/dashboard/dashboard.form.dto';
import { DashboardService } from '@zaoblako/web/core/services/dashboard/dashboard.service';
import { DashboardStateService } from '@zaoblako/web/modules/dashboard/components/dashboard/services/dashboard-state.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'crm-dashboard-add',
  templateUrl: './dashboard-add.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardAddComponent implements OnInit {
  public loading = false;
  public isShowDialogCreateDashboard$: Observable<boolean>;

  public constructor(
    private readonly dashboardService: DashboardService,
    private readonly dashboardStateService: DashboardStateService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.isShowDialogCreateDashboard$ = this.dashboardStateService.select((state) => state.isShowDialogCreateDashboard);
  }

  public save(body: DashboardFormDto) {
    this.loading = true;
    this.cdRef.markForCheck();

    this.dashboardService.create<DashboardFindDto>(body).subscribe({
      next: (dashboard) => {
        this.dashboardStateService.addDashboard(dashboard);
        this.loading = false;
        this.cdRef.markForCheck();
        this.closeDialog();
      },
      error: () => {
        this.loading = false;
        this.cdRef.markForCheck();
      }
    });
  }

  public closeDialog() {
    this.dashboardStateService.setIsShowDialogCreateDashboard(false);
  }
}

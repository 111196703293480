<crm-dashboard-widget
  [widgetData]="widgetData"
  [loading]="loading"
  [columns]="columns"
  (reload)="loadContracts()"
  (onScrollEnd)="onScrollEnd()"
>
  <ng-container *ngIf="contracts?.length">
    <crm-table
      name="contract-widget"
      [isMinTable]="true"
      [values]="contracts"
      [columns]="selectedColumns"
      [loading]="loading"
      [paginationLoading]="paginationLoading"
      [groupRowsBy]="selectedGroup?.parse"
      rowGroupMode="subheader"
      styleClass="sticky-header p-datatable-sm"
    >
      <ng-template #groupheaderTemplate let-item="item">
        <ng-container>
          <tr pRowGroupHeader class="surface-100">
            <td colspan="99">
              <ng-container *ngIf="selectedGroup.value === 'floor'">
                {{ item.floor?.name }}
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template #rowTemplate let-contract="item">
        <ng-container *ngIf="toContract(contract) as contract">
          <tr>
            <td>
              <a routerLink="/contract/card/{{ contract.id }}" target="_blank">
                {{ contract.name }} №{{ contract.number }}
              </a>
            </td>
            <td *ngIf="isSelectColumn('client')">
              <a routerLink="/client/card/{{ contract.person?.client?.id }}" target="_blank">
                {{ contract.person?.client?.name }}
              </a>
            </td>
            <td *ngIf="isSelectColumn('room')">
              <ng-container *ngFor="let room of contract.rooms; let index = index">
                <ng-container *ngIf="index">, </ng-container>
                <a routerLink="/location/room/card/{{ room.id }}" target="_blank">
                  {{ room.number }} {{ room.description }}
                </a>
              </ng-container>
            </td>
            <td *ngIf="isSelectColumn('zone')">
              <ng-container *ngFor="let room of contract.rooms; let index = index">
                <p>{{ room.zone?.name }}</p>
                <p class="greytext">{{ room.zone?.description }}</p>
              </ng-container>
            </td>
            <td *ngIf="isSelectColumn('floor')">
              <ng-container *ngFor="let room of contract.rooms; let index = index">
                <p>{{ room.floor?.number }}</p>
              </ng-container>
            </td>
            <td *ngIf="isSelectColumn('price')">
              {{ contract.price | number: '.0-0' }}
            </td>
            <td *ngIf="isSelectColumn('avgMonthPricesNds')">
              <ng-container *ngFor="let price of contract.avgMonthPricesNds; let index = index">
                <ng-container *ngIf="index">, </ng-container>
                {{ price | number: '.0-0' }}
              </ng-container>
            </td>
            <td *ngIf="isSelectColumn('daysToEnd')">
              {{ contract.daysToEnd | number: '.0-0' }}
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </crm-table>
  </ng-container>

  <p *ngIf="!loading && !contracts?.length" class="font-medium text-lg">Нет договоров</p>

  <ng-container content-button-list>
    <a
      pButton
      label="в список ({{ count || 0 | number }})"
      class="p-button-round p-button-text p-button-sm p-button-info -ml-3 mt-2"
      icon="pi pi-external-link"
      (click)="navigateToList()"></a>
  </ng-container>
</crm-dashboard-widget>

import { Injectable } from '@angular/core';
import { UserFindDto } from '@zaoblako/shared/modules/security/dtos/user/user.find.dto';
import { FileFindDto } from '@zaoblako/shared/modules/storage/dtos/file.find.dto';
import { TaskSecurityDto } from '@zaoblako/shared/modules/task/dtos/security/task.security.dto';
import { TaskSecurityQueryDto } from '@zaoblako/shared/modules/task/dtos/security/task.security.query.dto';
import { TaskCardDto } from '@zaoblako/shared/modules/task/dtos/task/task.card.dto';
import { TaskFieldValueFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.field.value.find.dto';
import { TaskFieldValueFormDto } from '@zaoblako/shared/modules/task/dtos/task/task.field.value.form.dto';
import { TaskFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.find.dto';
import { TaskFindQueryDto } from '@zaoblako/shared/modules/task/dtos/task/task.find.query.dto';
import { TaskHistoryFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.history.find.dto';
import { TaskListFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.list.find.dto';
import { TaskManagerFormDto } from '@zaoblako/shared/modules/task/dtos/task/task.manager.form.dto';
import { TaskStatusFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.status.find.dto';
import { TaskStepFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.step.find.dto';
import { TaskAgreementStatusEnum } from '@zaoblako/shared/modules/task/enums/task.agreement.status.enum';
import { TaskStatusTypeEnum } from '@zaoblako/shared/modules/task/enums/task.status.type.enum';
import { BaseService } from '@zaoblako/web/core/services/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends BaseService {
  protected baseUrl = '/task';

  public findWithPagination(queryParams: TaskFindQueryDto): Observable<TaskListFindDto> {
    return this.http.get<TaskListFindDto>('/tasks', { params: queryParams as any });
  }

  public findByStatusSecurity(
    queryParams: TaskSecurityQueryDto,
    taskStatus: TaskStatusTypeEnum
  ): Observable<TaskSecurityDto> {
    return this.http.get<TaskSecurityDto>(`${this.baseUrl}/security/${taskStatus.toLowerCase()}`, {
      params: queryParams as any
    });
  }

  public findByContract(contractId: number): Observable<TaskFindDto[]> {
    return this.http.get<TaskFindDto[]>(`${this.baseUrl}/contract/${contractId}`);
  }

  public managerCreate(task: TaskManagerFormDto): Observable<TaskFindDto> {
    return this.http.post<TaskFindDto>('/manager/task', task);
  }

  public findStatuses(): Observable<TaskStatusFindDto[]> {
    return this.http.get<TaskStatusFindDto[]>('/task-status');
  }

  public updateStatus(taskId: number, taskStatus: TaskStatusFindDto): Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/${taskId}/status`, taskStatus);
  }

  public findHistoryById(taskId: number): Observable<TaskHistoryFindDto[]> {
    return this.http.get<TaskHistoryFindDto[]>('/task-history/' + taskId);
  }

  public findTaskCardInfo(taskId: number): Observable<TaskCardDto> {
    return this.http.get<TaskCardDto>(`${this.baseUrl}/card/${taskId}`);
  }

  public generateBlank(taskId: number): Observable<FileFindDto> {
    return this.http.get<FileFindDto>(`${this.baseUrl}/generate/${taskId}`);
  }

  public delegate(taskId: number, responsible: UserFindDto): Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/${taskId}/delegate`, responsible);
  }

  public updateAgreement(agreementId: number, status: TaskAgreementStatusEnum): Observable<TaskStatusFindDto> {
    return this.http.put<TaskStatusFindDto>(`/task-agreement/${agreementId}/status`, { status });
  }

  public updateStepAgreement(stepAgreementId: number, status: TaskAgreementStatusEnum): Observable<null> {
    return this.http.put<null>(`/task-step-agreement/${stepAgreementId}/status`, { status });
  }

  public updateStep(step: TaskStepFindDto): Observable<null> {
    return this.http.put<null>(`/task-step/${step.id}`, step);
  }

  public updateTaskValue(taskFieldValue: TaskFieldValueFindDto): Observable<null> {
    return this.http.put<null>(`/task-field-value/${taskFieldValue.id}`, taskFieldValue);
  }

  public createTaskValue(taskFieldValue: TaskFieldValueFormDto): Observable<TaskFieldValueFindDto> {
    return this.http.post<TaskFieldValueFindDto>(`/task-field-value`, taskFieldValue);
  }
}

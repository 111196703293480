import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DashboardFindDto } from '@zaoblako/shared/modules/dashboard/dtos/dashboard/dashboard.find.dto';
import { DashboardFormDto } from '@zaoblako/shared/modules/dashboard/dtos/dashboard/dashboard.form.dto';
import { ConfirmDialogService } from '@zaoblako/web/core/services/confirm-dialog.service';
import { DashboardService } from '@zaoblako/web/core/services/dashboard/dashboard.service';
import { ErrorService } from '@zaoblako/web/core/services/error.service';
import { DashboardStateService } from '@zaoblako/web/modules/dashboard/components/dashboard/services/dashboard-state.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'crm-dashboard-edit-dialog',
  templateUrl: './dashboard-edit-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardEditDialogComponent implements OnInit {
  public display = false;
  public loading = false;
  public textContent = 'Настройка вкладки';

  public selectDashboard$: Observable<DashboardFindDto>;

  public constructor(
    private readonly dashboardService: DashboardService,
    private readonly dashboardStateService: DashboardStateService,
    private readonly confirmService: ConfirmDialogService,
    private readonly errorService: ErrorService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.selectDashboard$ = this.dashboardStateService.select((state) => state.selectDashboard);
  }

  public showDialog() {
    this.display = true;
  }

  public save(body: DashboardFormDto) {
    this.loading = true;
    this.cdRef.markForCheck();

    this.dashboardService.update<DashboardFindDto>(body).subscribe({
      next: (dashboard) => {
        this.dashboardStateService.updateDashboard(dashboard);
        this.errorService.addSuccessMessage(`Вкладка "${dashboard?.name}" изменена`);
        this.display = false;
        this.loading = false;
        this.cdRef.markForCheck();
      },
      error: () => {
        this.loading = false;
        this.cdRef.markForCheck();
      }
    });
  }

  public delete(dashboard: DashboardFindDto) {
    this.confirmService.confirm({
      message: `Вы действительно хотите удалить вкладку "${dashboard?.name}"?`,
      accept: () => {
        this.loading = true;
        this.cdRef.markForCheck();

        this.dashboardService.deleteById(dashboard?.id).subscribe({
          next: () => {
            this.dashboardStateService.deleteDashboard(dashboard?.id);
            this.errorService.addSuccessMessage(`Вкладка "${dashboard?.name}" удалена`);
            this.display = false;
            this.loading = false;
            this.cdRef.markForCheck();
          },
          error: () => {
            this.loading = false;
            this.cdRef.markForCheck();
          }
        });
      }
    });
  }
}

export enum ContractParamsStatusEnum {
  /**
   * Расторгнутый договор
   */
  DISSOLVED = 'DISSOLVED',
  /**
   * Действующий договор
   */
  ACTIVE = 'ACTIVE',
  /**
   * Истекающий договор
   */
  EXPIRING = 'EXPIRING',
}

<button
  pButton
  type="button"
  icon="pi pi-cog"
  class="p-button-outlined"
  [pTooltip]="textContent"
  tooltipPosition="left"
  (click)="showDialog()"
></button>

<p-dialog [(visible)]="display" [style]="{ width: '300px' }" [header]="textContent">
  <crm-dashboard-form
    *ngIf="selectDashboard$ | async; else spinner"
    [dashboard]="selectDashboard$ | async"
    [canDelete]="true"
    (save)="save($event)"
    (delete)="delete($any($event))"
  ></crm-dashboard-form>

  <ng-template #spinner>
    <crm-spinner></crm-spinner>
  </ng-template>
</p-dialog>

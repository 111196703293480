import { DashboardState } from '@zaoblako/web/modules/dashboard/components/dashboard/models/dashboard-state';

export const dashboardInitialState: DashboardState = {
  loading: true,
  isShowDialogCreateDashboard: false,
  dashboards: [],
  selectDashboard: null,
  dashboardTabItems: [],
  activeDashboardTabItem: null,
  filters: null
};

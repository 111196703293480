import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TreeSelect } from 'primeng/treeselect';

@Component({
  selector: 'crm-tree-select',
  templateUrl: './tree-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeSelectComponent implements OnChanges {
  @ViewChild('treeSelect') public treeSelect: TreeSelect;

  public _options: TreeNode[] = [];
  public _selectedItems: TreeNode[] = [];
  public _selectedItem: TreeNode = null;
  @Output() public selectedItemsChange = new EventEmitter<any>();
  @Output() public selectedItemChange = new EventEmitter<any>();
  @Input() public selectionMode: 'single' | 'multiple' | 'checkbox' = 'single';
  @Input() public dataKey: string = 'id';
  @Input() public childrenName = 'children';
  @Input() public optionLabel = 'name';
  @Input() public placeholder = '\u00A0';
  @Input() public inputId = `${Math.random()}`;
  @Input() public label: string;
  @Input() public sort = 'label:string';
  @Input() public disabled = false;
  @Input() public groupsDisabled = false;
  @Input() public emptyMessage = 'Список пуст';
  @Input() public selectedItemsOptions = { updateOn: 'default' };
  @Input() public appendTo: any;
  @Input() public scrollHeight = '300px';
  @Input() public display = 'chip';
  @Output() public changeValue = new EventEmitter<any>();
  @Output() public panelHide = new EventEmitter<void>();
  @Output() public panelShow = new EventEmitter<void>();
  @Output() public openedChange = new EventEmitter<boolean>();
  @Input() public class = '';
  @Input() public expanded = true;
  @Input() public metaKeySelection = false;

  private cacheSelectedItems: any[];
  private cacheSelectedItem: any;

  @Input() public set options(options: any[]) {
    if (!Array.isArray(options)) {
      this._options = [];
      return;
    }

    this._options = [];

    const parseChildren = (_options: any[]): TreeNode[] => {
      let list: TreeNode[] = [];
      if (Array.isArray(_options) && _options?.length) {
        _options
          .sort((a, b) => (a[this.optionLabel] < b[this.optionLabel] ? -1 : 1))
          .forEach((option: any) => {
            const children = parseChildren(option[this.childrenName]);
            list.push({
              label: option[this.optionLabel],
              key: option[this.dataKey]?.toString(),
              data: option,
              expanded: this.expanded,
              children,
              selectable: children?.length ? !this.groupsDisabled : true
            });
          });
      }
      return list;
    };

    this._options = parseChildren(options);
    if (this.selectionMode === 'single' && this.cacheSelectedItem) {
      this.selectedItem = this.cacheSelectedItem;
    } else if (
      (this.selectionMode === 'checkbox' || this.selectionMode === 'multiple') &&
      this.cacheSelectedItems?.length
    ) {
      this.selectedItems = this.cacheSelectedItems;
    }
  }

  @Input()
  public set selectedItem(selectedItem: any) {
    this._selectedItem = null;

    const parseChildren = (_options: TreeNode[]) => {
      _options.forEach((option) => {
        if (selectedItem?.[this.dataKey]?.toString() === option.key) {
          option.selectable = false;
          this._selectedItem = option;
        }
        if (this.cacheSelectedItem?.[this.dataKey]?.toString() === option.key) {
          option.selectable = option.children?.length ? !this.groupsDisabled : true;
        }
        if (option.children?.length) {
          parseChildren(option.children);
        }
      });
    };
    parseChildren(this._options);
    this.cacheSelectedItem = selectedItem;
  }

  @Input()
  public set selectedItems(selectedItems: any[]) {
    if (!Array.isArray(selectedItems)) {
      return;
    }

    this._selectedItems = [];

    const parseChildren = (_options: TreeNode[], parent?: TreeNode) => {
      _options.forEach((option) => {
        if (selectedItems.findIndex((si) => si[this.dataKey]?.toString() === option.key) !== -1) {
          this._selectedItems.push(option);
        }
        if (option.children?.length) {
          parseChildren(option.children, option);
        }
      });
      if (
        parent?.children?.every(
          (child) => this._selectedItems.findIndex((selectItem) => selectItem.key === child.key) !== -1
        ) &&
        this._selectedItems.findIndex((selectItem) => selectItem.key === parent?.key) === -1
      ) {
        this._selectedItems.push(parent);
      }
      if (
        !parent?.children?.every(
          (child) => this._selectedItems.findIndex((selectItem) => selectItem.key === child.key) !== -1
        ) &&
        this._selectedItems.findIndex((selectItem) => selectItem.key === parent?.key) !== -1
      ) {
        this._selectedItems = this._selectedItems.filter((selectItem) => selectItem.key !== parent?.key);
      }
    };

    parseChildren(this._options);
    this.cacheSelectedItems = selectedItems;
  }

  public ngOnChanges() {
    const updateItem = (options: TreeNode[]) => {
      options.forEach((opt) => {
        opt.selectable = opt.children?.length ? !this.groupsDisabled : true;
        if (this.selectionMode === 'single' && this._selectedItem?.key === opt.key) {
          opt.selectable = false;
        }
        if (opt.children?.length) {
          updateItem(opt.children);
        }
      });
    };

    updateItem(this._options);
  }

  public onHideChangeValue() {
    if (this.selectionMode !== 'single') {
      this.selectedItemsChange.emit(this._selectedItems.map((selectItem) => selectItem.data));
      this.changeValue.emit(this._selectedItems.map((selectItem) => selectItem.data));
    } else {
      this.selectedItemChange.emit(this._selectedItem?.data);
      this.changeValue.emit(this._selectedItem?.data);
    }
    this.openedChange.emit(false);
    this.panelHide.emit();
  }

  public onChangeValue(event: any) {
    if (this.selectionMode !== 'single') {
      this.changeValue.emit(event?.map((selectItem: any) => selectItem.data) || []);
      this.selectedItemsChange.emit(event?.map((selectItem: any) => selectItem.data) || []);
    } else {
      this.changeValue.emit(event?.data);
      this.selectedItemChange.emit(event?.data);
    }
  }

  public onPanelHide() {
    if (this.selectedItemsOptions.updateOn === 'hide') {
      this.onHideChangeValue();
    } else {
      this.panelHide.emit();
      this.openedChange.emit(false);
    }
  }
}

<div class="grid">
  <div class="col-12 lg:col-4">
    <p-card styleClass="h-full">
      <h3 class="mt-2 mb-0">
        Заявки
        <ng-container [ngSwitch]="currentUser?.id">
          <a
            *ngSwitchCase="27873"
            routerLink="/task"
            [queryParams]="{
              neededAgreement: true,
              templateIds: [260, 262, 264, 263, 251, 252],
              statusIds: getAcceptStatusIds,
              sort: 'created:date'
            }"
            >Михаил Горшенин</a
          >
          <a
            *ngSwitchCase="27895"
            routerLink="/task"
            [queryParams]="{ tagIds: 19, statusIds: getAcceptStatusIds, sort: 'created:date' }"
            >Рудяк Эрнест</a
          >
          <a
            *ngSwitchCase="29460"
            routerLink="/task"
            [queryParams]="{ tagIds: 18, statusIds: getAcceptStatusIds, sort: 'created:date' }"
            >Чекрыжов Павел</a
          >
          <a
            *ngSwitchCase="27899"
            routerLink="/task"
            [queryParams]="{ statusIds: getAcceptStatusIds, sort: 'created:date' }"
            >Бурт-Яшин И. Я.</a
          >
        </ng-container>
      </h3>
      <p class="greytext">{{ activePeriodLabel }}</p>
      <p-divider></p-divider>
      <p-table styleClass="borderless-table p-datatable-sm" [value]="[null]" [autoLayout]="true">
        <ng-template pTemplate="body">
          <ng-container *ngIf="taskStats; else taskStatsSkeleton">
            <tr *ngFor="let status of taskStats.all | orderBy: 'order:number'">
              <td class="fw-bold">
                <a
                  routerLink="/task"
                  [queryParams]="{ statusIds: status.id ? [status.id] : [] } | concatDateParams: activePeriodName"
                >
                  {{ status.name }}
                </a>
              </td>
              <td class="fw-bold text-right">{{ status.count | number }}</td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
      <p-divider></p-divider>
      <div class="text-right">
        <button
          pButton
          label="Подробнее..."
          class="p-button-text p-button-secondary p-button-sm"
          routerLink="/task"
          [queryParams]="{ statusIds: getActiveStatusIds } | concatDateParams: activePeriodName"
        ></button>
      </div>
    </p-card>
  </div>
  <div class="col-12 lg:col-4">
    <p-card styleClass="h-full">
      <h3 class="mt-2 mb-0">Заявки на аренду</h3>
      <p class="greytext">{{ activePeriodLabel }}</p>
      <p-divider></p-divider>
      <p-table styleClass="borderless-table p-datatable-sm" [value]="[null]" [autoLayout]="true">
        <ng-template pTemplate="body">
          <ng-container *ngIf="taskStats; else taskStatsSkeleton">
            <tr *ngFor="let status of taskStats.rent.data | orderBy: 'order:number'">
              <td class="fw-bold">
                <a
                  routerLink="/task"
                  [queryParams]="
                    { templateIds: taskStats.rent.template.id, statusIds: status.id ? [status.id] : [] }
                      | concatDateParams: activePeriodName
                  "
                >
                  {{ status.name }}
                </a>
              </td>
              <td class="fw-bold text-right">{{ status.count | number }}</td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
      <p-divider></p-divider>
      <div class="text-right">
        <button
          *ngIf="taskStats"
          pButton
          label="Подробнее..."
          class="p-button-text p-button-secondary p-button-sm"
          routerLink="/task"
          [queryParams]="
            { templateIds: taskStats.rent.template.id, statusIds: getActiveStatusIds }
              | concatDateParams: activePeriodName
          "
        ></button>
      </div>
    </p-card>
  </div>
  <div class="col-12 lg:col-4">
    <p-card styleClass="h-full">
      <h3 class="mt-2 mb-0">Для совещания</h3>
      <p class="greytext">{{ activePeriodLabel }}, заявки на скидку готовые к рассмотрению</p>
      <p-divider></p-divider>
      <p-table styleClass="borderless-table p-datatable-sm" [value]="[null]" [autoLayout]="true">
        <ng-template pTemplate="body">
          <ng-container *ngIf="taskStats; else taskStatsSkeleton">
            <tr *ngFor="let status of taskStats.meeting">
              <td class="fw-bold">
                <a
                  routerLink="/task"
                  [queryParams]="
                    { templateIds: status.template.id, statusIds: getAcceptStatusIds }
                      | concatDateParams: activePeriodName
                  "
                >
                  {{ status.template.name }}
                </a>
              </td>
              <td class="fw-bold text-right">{{ status.count | number }}</td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
      <p-divider></p-divider>
      <div class="text-right">
        <button
          pButton
          label="Подробнее..."
          class="p-button-text p-button-secondary p-button-sm"
          routerLink="/task"
          [queryParams]="{ statusIds: getAcceptStatusIds } | concatDateParams: activePeriodName"
        ></button>
      </div>
    </p-card>
  </div>
</div>

<ng-template #taskStatsSkeleton>
  <tr *ngFor="let _ of skeletonItems">
    <td>
      <p-skeleton width="100%"></p-skeleton>
    </td>
  </tr>
</ng-template>

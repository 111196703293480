import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DashboardFormDto } from '@zaoblako/shared/modules/dashboard/dtos/dashboard/dashboard.form.dto';
import { BaseFormComponent } from '@zaoblako/web/shared/dumbs/base-form/base-form.component';

@Component({
  selector: 'crm-dashboard-form',
  templateUrl: './dashboard-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardFormComponent extends BaseFormComponent<DashboardFormDto> {
  @Input() public dashboard = new DashboardFormDto();
  public dto = DashboardFormDto;
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContractWithMoreFieldsFindDto } from '@zaoblako/shared/modules/contract/dtos/contract.with.more.field.find.dto';
import { ContractParamsStatusEnum } from '@zaoblako/shared/modules/contract/enums/contract.params.status.enum';
import { WidgetContractsQueryDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/contracts/widget.contracts.query.dto';
import { WidgetFindDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/widget.find.dto';
import { PeriodEnum } from '@zaoblako/shared/modules/report/enums/period.enum';
import { CrmTableColumn } from '@zaoblako/web/core/models/crm-table-column.interface';
import { DashboardService } from '@zaoblako/web/core/services/dashboard/dashboard.service';
import { DashboardStateService } from '@zaoblako/web/modules/dashboard/components/dashboard/services/dashboard-state.service';
import * as moment from 'moment-timezone';
import { unitOfTime } from 'moment-timezone';

type ColumnName = 'number' | 'client' | 'room' | 'zone' | 'floor' | 'price' | 'avgMonthPricesNds' | 'daysToEnd';

@Component({
  selector: 'crm-widget-contracts',
  templateUrl: './widget-contracts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetContractsComponent implements OnInit {
  @Input()
  public widgetData: WidgetFindDto;

  public queryParams: WidgetContractsQueryDto = {
    limit: 50,
    offset: 0
  };

  public loading = false;
  public contracts: ContractWithMoreFieldsFindDto[] = [];
  public count: number;
  public paginationLoading = false;

  public readonly groupOptions: { label: string; value: 'floor'; parse: string }[] = [
    { label: 'Этаж', value: 'floor', parse: 'floor.id' }
  ];

  public selectedGroup: { label: string; value: 'floor'; parse: string };

  public readonly columns: CrmTableColumn<ColumnName>[] = [
    { label: 'Номер', name: 'number', required: true },
    { label: 'Клиент', name: 'client' },
    { label: 'Помещение', name: 'room' },
    { label: 'Зона', name: 'zone' },
    { label: 'Этаж', name: 'floor' },
    { label: 'Абонентка', name: 'price' },
    { label: '% отклонения от ср. по зоне', name: 'avgMonthPricesNds' },
    { label: 'Дней до окончания', name: 'daysToEnd' }
  ];
  public selectedColumns: CrmTableColumn<ColumnName>[] = [];

  public constructor(
    private readonly dashboardService: DashboardService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.queryParams.groupBy = this.widgetData.groupBy;
    this.selectedGroup = this.groupOptions.find((option) => option.value === this.widgetData.groupBy);
    this.loadContracts();
  }

  public loadContracts(isConcat = false) {
    if (isConcat) {
      this.paginationLoading = true;
      this.queryParams.offset += this.queryParams.limit;
    } else {
      this.loading = true;
      this.queryParams.offset = 0;
    }
    this.cdRef.markForCheck();

    this.queryParams.status = this.widgetData.filter.contractStatus;
    this.queryParams.daysToEnd = this.widgetData.filter.daysToEnd;
    this.queryParams.sortColumn = this.widgetData.sortColumn;
    this.queryParams.sortDirection = this.widgetData.sortDirection;

    this.selectedColumns = this.columns.filter((column) => this.widgetData.selectedColumns?.includes(column.name));

    this.dashboardService.contractsWidget(this.queryParams).subscribe({
      next: (data) => {
        this.contracts = isConcat ? this.contracts.concat(data.contracts) : data.contracts;
        this.count = data.count;
        this.loading = false;
        this.paginationLoading = false;
        this.cdRef.markForCheck();
      }
    });
  }

  public toContract(contract: any): ContractWithMoreFieldsFindDto {
    return contract as ContractWithMoreFieldsFindDto;
  }

  public onScrollEnd(): void {
    if (this.contracts.length < this.count && !this.loading && !this.paginationLoading) {
      this.loadContracts(true);
    }
  }

  public isSelectColumn(name: ColumnName): boolean {
    return this.selectedColumns?.some((selectColumn) => selectColumn.name === name);
  }

  public navigateToList() {
    let path: string;
    switch (this.widgetData.filter?.contractStatus) {
      case ContractParamsStatusEnum.ACTIVE: {
        path = '/contract/active';
        break;
      }
      case ContractParamsStatusEnum.DISSOLVED: {
        path = '/contract/dissolved';
        break;
      }
      case ContractParamsStatusEnum.EXPIRING: {
        path = '/contract/expiring';
        break;
      }
      default: {
        path = '/contract/active';
        break;
      }
    }
    const url = String(this.router.createUrlTree([path]));
    window.open(url, '_blank');
  }
}

import { Injectable } from '@angular/core';
import { UserFindDto } from '@zaoblako/shared/modules/security/dtos/user/user.find.dto';
import { FileFindDto } from '@zaoblako/shared/modules/storage/dtos/file.find.dto';
import { TaskSecurityDto } from '@zaoblako/shared/modules/task/dtos/security/task.security.dto';
import { TaskSecurityQueryDto } from '@zaoblako/shared/modules/task/dtos/security/task.security.query.dto';
import { TaskCardDto } from '@zaoblako/shared/modules/task/dtos/task/task.card.dto';
import { TaskFieldValueFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.field.value.find.dto';
import { TaskFieldValueFormDto } from '@zaoblako/shared/modules/task/dtos/task/task.field.value.form.dto';
import { TaskFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.find.dto';
import { TaskFindQueryDto } from '@zaoblako/shared/modules/task/dtos/task/task.find.query.dto';
import { TaskFormDto } from '@zaoblako/shared/modules/task/dtos/task/task.form.dto';
import { TaskHistoryFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.history.find.dto';
import { TaskListFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.list.find.dto';
import { TaskManagerFormDto } from '@zaoblako/shared/modules/task/dtos/task/task.manager.form.dto';
import { TaskStatusFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.status.find.dto';
import { TaskStepFindDto } from '@zaoblako/shared/modules/task/dtos/task/task.step.find.dto';
import { TaskAgreementStatusEnum } from '@zaoblako/shared/modules/task/enums/task.agreement.status.enum';
import { TaskFieldDiscriminatorEnum } from '@zaoblako/shared/modules/task/enums/task.field.discriminator.enum';
import { TaskStatusTypeEnum } from '@zaoblako/shared/modules/task/enums/task.status.type.enum';
import { BaseStateService } from '@zaoblako/web/core/services/base-state-service';
import { TaskService } from '@zaoblako/web/core/services/task/task.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskStateService extends BaseStateService {

  public updateAgreementEvent = new Subject();
  public updateStepAgreementEvent = new Subject();

  private currentTask: TaskFindDto;
  private currentTaskValuesByTag: Record<string, TaskFieldValueFindDto> = {};
  private currentTaskValuesByType: Partial<Record<TaskFieldDiscriminatorEnum, TaskFieldValueFindDto>> = {};

  public constructor(private readonly taskService: TaskService) {
    super();
    this.baseService = taskService;
  }

  public findWithPagination(queryParams: TaskFindQueryDto): Observable<TaskListFindDto> {
    return this.taskService.findWithPagination(queryParams);
  }

  public findByStatusSecurity(queryParams: TaskSecurityQueryDto, taskStatus: TaskStatusTypeEnum): Observable<TaskSecurityDto> {
    return this.taskService.findByStatusSecurity(queryParams, taskStatus);
  }

  public findByContract(contractId: number): Observable<TaskFindDto[]> {
    return this.taskService.findByContract(contractId);
  }

  public managerCreate(task: TaskManagerFormDto): Observable<TaskFindDto> {
    return this.taskService.managerCreate(task);
  }

  public findStatuses(): Observable<TaskStatusFindDto[]> {
    return this.taskService.findStatuses();
  }

  public updateStatus(taskId: number, taskStatus: TaskStatusFindDto) {
    return this.taskService.updateStatus(taskId, taskStatus);
  }

  public findHistoryById(taskId: number): Observable<TaskHistoryFindDto[]> {
    return this.taskService.findHistoryById(taskId);
  }

  public findTaskCardInfo(taskId: number): Observable<TaskCardDto> {
    return this.taskService.findTaskCardInfo(taskId);
  }

  public generateBlank(taskId: number): Observable<FileFindDto> {
    return this.taskService.generateBlank(taskId);
  }

  public delegate(taskId: number, responsible: UserFindDto): Observable<null> {
    return this.taskService.delegate(taskId, responsible);
  }

  public updateAgreement(agreementId: number, status: TaskAgreementStatusEnum): Observable<TaskStatusFindDto> {
    return this.taskService.updateAgreement(agreementId, status);
  }

  public updateStepAgreement(stepAgreementId: number, status: TaskAgreementStatusEnum): Observable<null> {
    return this.taskService.updateStepAgreement(stepAgreementId, status);
  }

  public findCurrentStep(steps: TaskStepFindDto[]): TaskStepFindDto {
    return steps
      .sort((a, b) => a.step.order - b.step.order)
      .find(step => step.agreements?.some(agreement => agreement.status !== TaskAgreementStatusEnum.TASK_AGREEMENT_AGREED)) || steps[steps.length - 1];
  }

  public updateStep(step: TaskStepFindDto): Observable<null> {
    return this.taskService.updateStep(step);
  }

  public setCurrentTask(task: TaskFindDto) {
    this.clearCurrentTask();
    this.currentTask = task;
    task.values.forEach(taskValue => {
      if (taskValue.field.tag) {
        this.currentTaskValuesByTag[taskValue.field.tag] = taskValue;
      }
      if (taskValue.type) {
        this.currentTaskValuesByType[taskValue.type] = taskValue;
      }
    });
  }

  public clearCurrentTask() {
    this.currentTaskValuesByTag = {};
    this.currentTaskValuesByType = {};
    this.currentTask = null;
  }

  public getCurrentTask(): TaskFindDto {
    return this.currentTask;
  }

  public getCurrentTaskValueByTag(tag: string): TaskFieldValueFindDto {
    return this.currentTaskValuesByTag[tag];
  }

  public getCurrentTaskValueByType(type: TaskFieldDiscriminatorEnum): TaskFieldValueFindDto {
    return this.currentTaskValuesByType[type];
  }

  public updateTaskValue(taskFieldValue: TaskFieldValueFindDto): Observable<null> {
    return this.taskService.updateTaskValue(taskFieldValue);
  }

  public createTaskValue(taskFieldValue: TaskFieldValueFormDto): Observable<TaskFieldValueFindDto> {
    return this.taskService.createTaskValue(taskFieldValue);
  }

  public sortFieldValue(task: TaskFormDto | TaskManagerFormDto) {
    task.values.filter((taskValue) => taskValue.type === TaskFieldDiscriminatorEnum.TASK_FIELD_TYPE_TABLE)
        .forEach((taskValue) => {
      if (taskValue.value[0]) {
        const fieldId = taskValue.field.children.find((value) => value.tag === 'fio')?.id;
        if (fieldId) {
          taskValue.value?.sort((a, b) => {
            const indexA = a.findIndex((value) => value.field?.id === fieldId);
            const indexB = b.findIndex((value) => value.field?.id === fieldId);
            if (indexA !== -1 && indexB !== -1) {
              if (a[indexA].value > b[indexB].value) {
                return 1;
              } else if (a[indexA].value < b[indexB].value) {
                return -1;
              }
              return 0;
            }
          });
        }
      }
    });
  }

}

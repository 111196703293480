import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AvailableWidgetTreeDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/available.widget.tree.dto';
import { AvailableWidgetTypeDto } from '@zaoblako/shared/modules/dashboard/dtos/widget/available.widget.type.dto';
import { DashboardWidgetTypeGroupTranslatePipe } from '@zaoblako/web/shared/pipes/dashboard-widget-type-group-translate.pipe';

@Component({
  selector: 'crm-widget-types-single-select',
  templateUrl: './widget-types-single-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetTypesSingleSelectComponent {
  public _types: any[] = [];
  public _selectedWidgetType: AvailableWidgetTypeDto;

  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public styleClass: string;
  @Input() public scrollHeight = '300px';
  @Input() public appendTo = '';
  @Input() public groupsDisabled = false;

  @Input() public set widgetTypes(widgetTypes: AvailableWidgetTreeDto[]) {
    if (!Array.isArray(widgetTypes)) {
      this._types = [];
      return;
    }
    this._types = widgetTypes.map((type) => ({
      name: new DashboardWidgetTypeGroupTranslatePipe().transform(type.group),
      type: type.group,
      children: type.children.sort((a, b) => (a.name < b.name ? -1 : 1))
    }));
  }

  @Input() public set selectedWidgetType(widgetType: AvailableWidgetTypeDto) {
    this._selectedWidgetType = widgetType;
  }

  @Output() public panelShow = new EventEmitter<any>();
  @Output() public panelHide = new EventEmitter<any>();
  @Output() public selectedWidgetTypeChange = new EventEmitter<AvailableWidgetTypeDto>();

  public changeSelectedWidgetType(widgetType: AvailableWidgetTypeDto) {
    this.selectedWidgetTypeChange.emit(widgetType);
  }
}

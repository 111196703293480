<p-card styleClass="very-compact-card pt-3 px-3 pb-2 h-full p-card-full">
  <div class="flex justify-content-between mb-2 isDrag">
    <div class="uppercase text-base font-medium text-color-secondary flex align-items-center gap-2">
      <p class="cursor-pointer">{{ widgetData.title }}</p>
      <i *ngIf="loading" class="pi pi-spin pi-spinner"></i>
    </div>
    <div class="flex align-items-center text-600">
      <i class="pi pi-refresh mr-4 cursor-pointer hover:color-blue" (click)="reload.emit()"></i>
      <i class="pi pi-cog mr-4 cursor-pointer hover:color-blue" (click)="widgetSettingsOverlay.toggle($event)"></i>
      <p-overlayPanel #widgetSettingsOverlay>
        <div class="p-fluid w-20rem">
          <div class="field">
            <label class="font-medium">Заголовок</label>
            <crm-input-text
              #inputText
              [(value)]="widgetData.title"
              [showClear]="true"
              [maxLength]="60"
              [isError]="widgetData.title?.length >= 60"
            >
            </crm-input-text>
            <small *ngIf="widgetData.title?.length >= 60" class="p-error">
              Длина названия виджета должна быть меньше 60 символов
            </small>
          </div>
          <div class="field grid">
            <div class="field col mb-0">
              <label class="font-medium">Ширина</label>
              <p-selectButton
                [options]="sizeOptions"
                [ngModel]="widgetData.width"
                (ngModelChange)="changeWidgetDataWidth($event)"
              ></p-selectButton>
            </div>
            <div class="field col mb-0">
              <label class="font-medium">Высота</label>
              <p-selectButton [options]="sizeOptions" [(ngModel)]="widgetData.height"></p-selectButton>
            </div>
          </div>
          <div *ngIf="showFilterColumnFromWidgetType.includes(widgetData.type)" class="field">
            <label class="font-medium">Колонки</label>
            <crm-multi-select
              [options]="columns | filterEmpty: 'name'"
              [selectedItems]="selectedColumns | filterEmpty: 'name'"
              (selectedItemsChange)="selectedFieldsChange($event)"
              [showHeader]="false"
              scrollHeight="200px"
              optionDisabled="required"
              sort="order"
              optionLabel="label"
              dataKey="name"
            ></crm-multi-select>
          </div>
          <div *ngIf="showFilterGroupByFromWidgetType.includes(widgetData.type)" class="field">
            <label class="font-medium">Группировка</label>
            <crm-single-select
              [options]="groupOptions | filterEmpty: 'value'"
              [(selectedItem)]="selectedGroupOption"
              [showClear]="true"
              scrollHeight="200px"
              placeholder="Выберите группировку"
              optionLabel="label"
              dataKey="value"
            ></crm-single-select>
          </div>
          <div *ngIf="showFilterSortFromWidgetType.includes(widgetData.type)" class="field">
            <label class="font-medium">Сортировка</label>
            <crm-single-select
              [options]="sortOptions"
              [(selectedItem)]="selectedSortOption"
              [isTemplateCombined]="true"
              [filter]="false"
              scrollHeight="200px"
              placeholder="Выберите сортировку"
              optionLabel="label"
              dataKey="id"
            >
              <ng-template #itemTemplate let-item="item">
                <div *ngIf="toSortItem(item) as item" class="flex align-items-center gap-2">
                  <div>{{ item.label }}</div>
                  <i *ngIf="item.direction === SortDirectionEnum.ASC" class="pi pi-arrow-up"></i>
                  <i *ngIf="item.direction === SortDirectionEnum.DESC" class="pi pi-arrow-down"></i>
                </div>
              </ng-template>
            </crm-single-select>
          </div>
          <div *ngIf="showFilterPeriodFromWidgetType.includes(widgetData.type)" class="field">
            <label class="font-medium">Период</label>
            <p-selectButton [options]="periodOptions" [(ngModel)]="widgetData.filter.period"> </p-selectButton>
          </div>
          <div *ngIf="showFilterStatusFromWidgetType.includes(widgetData.type)" class="field">
            <label class="font-medium">Статусы</label>
            <crm-multi-select
              [options]="(filters$ | async)?.statuses"
              [(selectedItems)]="widgetData.filter.statuses"
              scrollHeight="200px"
              placeholder="Выберите статусы"
            ></crm-multi-select>
          </div>
          <div *ngIf="showFilterTaskTemplateFromWidgetType.includes(widgetData.type)" class="field">
            <label class="font-medium">Шаблон</label>
            <crm-multi-select
              [options]="(filters$ | async)?.templates"
              [(selectedItems)]="widgetData.filter.templates"
              filterBy="name,tag"
              itemDescriptionField="tag"
              scrollHeight="200px"
              placeholder="Выберите шаблоны"
            ></crm-multi-select>
          </div>
          <div *ngIf="showFilterResponsibleFromWidgetType.includes(widgetData.type)" class="field">
            <label class="font-medium">Ответственный</label>
            <crm-multi-select
              [options]="(filters$ | async)?.responsible"
              [(selectedItems)]="widgetData.filter.responsible"
              [virtualScroll]="true"
              itemDescriptionField="clients.0.name"
              scrollHeight="200px"
              placeholder="Выберите ответственных"
            ></crm-multi-select>
          </div>
          <div *ngIf="showFilterAgreementFromWidgetType.includes(widgetData.type)" class="field">
            <label class="font-medium">Согласователь</label>
            <crm-multi-select
              [options]="(filters$ | async)?.responsible"
              [(selectedItems)]="widgetData.filter.agreement"
              [virtualScroll]="true"
              itemDescriptionField="clients.0.name"
              scrollHeight="200px"
              placeholder="Выберите согласователей"
            ></crm-multi-select>
          </div>
          <div *ngIf="showFilterTagFromWidgetType.includes(widgetData.type)" class="field">
            <label class="font-medium">Тэг</label>
            <crm-multi-select
              [options]="(filters$ | async)?.tags"
              [(selectedItems)]="widgetData.filter.tag"
              scrollHeight="200px"
              placeholder="Выберите тэги"
            ></crm-multi-select>
          </div>
          <div *ngIf="showFilterContractStatusFromWidgetType.includes(widgetData.type)" class="field">
            <label class="font-medium">Статус</label>
            <crm-single-select
              dataKey="name"
              scrollHeight="200px"
              [options]="ContractStatus"
              [filter]="false"
              [isTemplateCombined]="true"
              [showClear]="true"
              [(selectedItem)]="widgetData.filter.contractStatus"
            >
              <ng-template #itemTemplate let-status="item">
                {{ status?.value | contractParamsStatusName }}
              </ng-template>
            </crm-single-select>
          </div>
          <div *ngIf="showFilterDaysToEndFromWidgetType.includes(widgetData.type)" class="field">
            <label class="font-medium">Дней до окончания</label>
            <p-inputNumber [(ngModel)]="widgetData.filter.daysToEnd" [min]="0"></p-inputNumber>
          </div>
          <div *ngIf="showFilterIsMyApprovalFromWidgetType.includes(widgetData.type)" class="field-checkbox">
            <p-checkbox
              [(ngModel)]="widgetData.filter.isMyApproval"
              [binary]="true"
              inputId="isMyApproval"
            ></p-checkbox>
            <label [for]="'isMyApproval'" class="cursor-pointer">Требуется мое согласование</label>
          </div>
          <div *ngIf="showFilterIgnorePeriodFromWidgetType.includes(widgetData.type)" class="field-checkbox">
            <p-checkbox
              [(ngModel)]="widgetData.filter.ignorePeriod"
              [binary]="true"
              inputId="ignorePeriod"
            ></p-checkbox>
            <label [for]="'ignorePeriod'" class="cursor-pointer">Не учитывать период</label>
          </div>

          <div class="field mt-4">
            <button
              pButton
              label="Сохранить"
              class="p-button-success"
              (click)="updateWidget(); widgetSettingsOverlay.hide()"
            ></button>
          </div>
        </div>
      </p-overlayPanel>
      <i class="pi pi-times cursor-pointer hover:color-red" (click)="deleteWidget()"></i>
    </div>
  </div>
  <div class="flex flex-column h-full justify-content-between">
    <div
      class="overflow-y-auto"
      [ngStyle]="{ 'max-height': widgetData.height * 200 - 95 + 'px' }"
      (scroll)="scroll($any($event.target))"
    >
      <ng-content></ng-content>
    </div>
    <div>
      <ng-content select="[content-button-list]"></ng-content>
    </div>
  </div>
</p-card>

<crm-dashboard-widget [widgetData]="widgetData" [loading]="loading" (reload)="load()">
  <crm-table
    name="report-widget"
    [isMinTable]="true"
    [values]="items"
    [loading]="loading"
    styleClass="borderless-table"
  >
    <ng-template #rowTemplate let-item="item">
      <tr *ngIf="toItem(item) as item">
        <td class="fw-bold">
          <a [routerLink]="item.url">
            {{ item.name }}
          </a>
        </td>
        <td>
          {{ item.description }}
        </td>
      </tr>
    </ng-template>
  </crm-table>
</crm-dashboard-widget>
